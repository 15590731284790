import React, { FC, useEffect } from "react";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { colors, HyphenForInput } from "../../styles";
import { DateInput } from "./DateInput";
import { Container, Title, Wrapper } from "./DateInput.styles";
import { useDateInput } from "./useDateInput";

export const StartAndEndDatesInput: FC<PropsType> = (props) => {
  const { label, column = false, merge = false, color = colors.grayscaleInput } = props;
  const { minDate, maxDate, disabled, title } = props;

  const methods = useDateInput(props.name, props.placeholder, label);
  const { maxStartDate, minEndDate, name, placeholder } = methods;

  const context = useFormContext();

  const startController = useController({ name: name.start });
  const startError = startController.fieldState.error?.message;
  const startTouched = startController.fieldState.isTouched;

  const stopController = useController({ name: name.stop });
  const endError = stopController.fieldState.error?.message;
  const stopTouched = stopController.fieldState.isTouched;

  const startDate = useWatch({ name: name.start }) as Date;
  const endDate = useWatch({ name: name.stop }) as Date;

  useEffect(() => {
    if (!!startTouched || !!stopTouched) {
      context.trigger([name.start, name.stop]);
    }
  }, [startTouched, stopTouched]);

  return (
    <Wrapper>
      {title ? <Title>{title}</Title> : null}
      <Container
        error={(!!startTouched || !!stopTouched) && !!startError && !!endError}
        column={column}
        merge={merge}
        backgroundcolor={color}
      >
        <DateInput
          name={name.start}
          placeholder={placeholder.start}
          label={label?.start}
          disabled={!!disabled?.start}
          minDate={minDate}
          maxDate={maxStartDate(endDate, maxDate)}
          color={color}
        />

        {merge && <HyphenForInput needLabel={!!label?.stop}>—</HyphenForInput>}

        <DateInput
          name={name.stop}
          placeholder={placeholder.stop}
          label={label?.stop}
          disabled={!!disabled?.stop}
          minDate={minEndDate(startDate, minDate)}
          maxDate={maxDate}
          color={color}
        />
      </Container>
    </Wrapper>
  );
};

type PropsType = {
  name?: { start: string; stop: string };
  label?: { start: string; stop: string };
  title?: string;
  placeholder?: { start: string; stop: string };
  disabled?: { start?: boolean; stop?: boolean };
  minDate?: Date;
  maxDate?: Date;
  merge?: boolean;
  column?: boolean;
  color?: string;
};
