import { Badge, Loader } from "@digitalatom/ui";
import React, { FC, MouseEvent, Suspense, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Notifications } from "../../../components";
import { LOGOUT_HREF, USER_PERSONAL_INFO } from "../../../constants/routes";
import { ProfileMenuButton, ProfileMenuButtons } from "./Profile.styles";

export const ProfileMenu: FC<PropsType> = (props) => {
  const { notificationsQuantity, canViewNotifications, canViewProfile, onClose } = props;
  const { personnelNumber } = props;

  const [notificationsIsOpen, setNotificationsIsOpen] = useState(false);

  const navigate = useNavigate();

  const logout = (evt: MouseEvent<HTMLElement>) => {
    window.location.href = LOGOUT_HREF;
    onClose(evt);
  };

  return (
    <>
      <ProfileMenuButtons>
        {canViewNotifications && (
          <ProfileMenuButton
            onClick={() => {
              setNotificationsIsOpen(true);
            }}
          >
            <p>Уведомления</p>

            {!!notificationsQuantity && (
              <Badge text={notificationsQuantity} size="s" design="withStroke" type="accent" />
            )}
          </ProfileMenuButton>
        )}

        {canViewProfile && (
          <ProfileMenuButton
            onClick={(evt) => {
              navigate(USER_PERSONAL_INFO(personnelNumber));
              onClose(evt);
            }}
          >
            <p>Профиль</p>
          </ProfileMenuButton>
        )}

        <ProfileMenuButton onClick={logout}>
          <p>Выйти</p>
        </ProfileMenuButton>
      </ProfileMenuButtons>

      {canViewNotifications && (
        <Suspense fallback={<Loader />}>
          <Notifications
            open={notificationsIsOpen}
            onClose={() => setNotificationsIsOpen(false)}
            closeProfileMenu={onClose}
          />
        </Suspense>
      )}
    </>
  );
};

type PropsType = {
  notificationsQuantity?: number;
  canViewNotifications: boolean;
  canViewProfile: boolean;
  onClose: (evt: MouseEvent<HTMLElement>) => void;
  personnelNumber: string;
};
