import { TriangleDown, TriangleUp } from "@digitalatom/ui";
import React, { FC, ReactNode, useState } from "react";
import { Container, Content, ExpansionButton, Text, Title, TitleAndArray } from "./SubBlock.styles";

export const SubBlock: FC<PropsType> = (props) => {
  const { title, content, isTitleSelected, isNeedSeparatedLine = true } = props;
  const { id } = props;

  const titleWithExpansion = [
    "Профили лечения",
    "Методы лечения",
    "Услуги санатория",
    "Описание",
    "Подробнее",
    "Рейтинг",
  ];

  const [expandedTitles, setExpandedTitles] = useState<string[]>(titleWithExpansion);

  const handleExpansion = () =>
    setExpandedTitles((prevState) =>
      prevState.includes(title)
        ? prevState.filter((datum) => datum !== title)
        : [...prevState, title]
    );

  const needExpansionTitle = titleWithExpansion.includes(title);
  const expandedTitle = expandedTitles.includes(title);

  return (
    <Container id={id}>
      <TitleAndArray isNeedSeparatedLine={isNeedSeparatedLine}>
        <Title selected={isTitleSelected}>{title}</Title>

        {needExpansionTitle ? (
          <ExpansionButton onClick={handleExpansion}>
            {expandedTitle ? <TriangleUp /> : <TriangleDown />}
          </ExpansionButton>
        ) : null}
      </TitleAndArray>

      {(!needExpansionTitle || (needExpansionTitle && expandedTitle)) && (
        <Content>{typeof content === "string" ? <Text>{content}</Text> : content}</Content>
      )}
    </Container>
  );
};

type PropsType = {
  title: string;
  id?: string;
  content: string | ReactNode;
  isTitleSelected?: boolean;
  isNeedSeparatedLine?: boolean;
};
