import { Export } from "@digitalatom/ui";
import React, { DragEventHandler, FC, MouseEvent, RefObject } from "react";
import { fileSizeConversion } from "../../utils/fileSizeConversion";
import { preventDefault } from "../../utils/preventDefault";
import * as elements from "./DocumentsInput.styles";

export const InputEmpty: FC<PropsType> = (props) => {
  const { accept, maxSize, element, addFile, onDownloadFromProfile, oneDocument } = props;

  const { Container, Title, UploadLink, Description, Text, IconAndText, LoadBar } = elements;

  const handleDragOver: DragEventHandler<HTMLElement> = (evt) => preventDefault(evt);

  const handleDragLeave: DragEventHandler = (evt) => preventDefault(evt);

  const handleDrop: DragEventHandler = (evt) => {
    preventDefault(evt);

    const files = evt.dataTransfer.files;

    addFile(files);

    if (element.current?.files) element.current.files = files;
  };

  const description = maxSize ? `Файл не более ${fileSizeConversion(maxSize)}, в формате ` : "";

  return (
    <Container
      status="empty"
      onClick={() => element.current?.click()}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div></div>

      <IconAndText status="empty">
        <LoadBar status="empty">
          <Export />
        </LoadBar>

        <Text>
          <Title fullWidth={!!onDownloadFromProfile} status="empty">
            {onDownloadFromProfile ? (
              <>
                Перетащите файлы или загрузите{" "}
                <UploadLink
                  onMouseEnter={(evt) => evt.stopPropagation()}
                  onClick={onDownloadFromProfile}
                >
                  из профиля
                </UploadLink>{" "}
                или <UploadLink>с компьютера</UploadLink>
              </>
            ) : oneDocument ? (
              "Перетащите файл в область загрузки или выберите по нажатию"
            ) : (
              "Перетащите файлы в область загрузки или выберите их"
            )}
          </Title>

          <Description status="empty">
            {description ? description : "Подходят файлы в формате 24x24px "}
            {accept.join(" ")}
          </Description>
        </Text>
      </IconAndText>
    </Container>
  );
};

type PropsType = {
  accept: string[];
  maxSize: number;
  addFile: (file: FileList) => void;
  element: RefObject<HTMLInputElement>;
  onDownloadFromProfile?: (evt: MouseEvent<HTMLDivElement>) => void;
  oneDocument: boolean;
};
