/**
 *
 * ------------------------------------------------------------------------------------------
 * **ФОРМАТИРОВАНИЕ ЦЕН**
 *
 * *Форматирует стоимость, добавляя знак валюты (₽) и пробелы для разделения разрядов*
 *
 * *Если входное значение некорректно, возвращает символ "—"*
 *
 * -
 *
 * @param price - стоимость, которую необходимо отформатировать (строка или число)
 * @returns отформатированная строка с ценой, дополненная символом ₽, или "—" для некорректных значений
 *
 * @description
 * *Особенности*:
 * - удаляет лидирующие нули в числах
 * - разделяет тысячные разряды пробелами
 *
 */

export const formatPrice = (price: string | number) => {
  if (Array.isArray(price) || price === "") return "—";

  const formattedPrice = String(price).replace(/^0+/, "") || "0";

  return /^\d+(\.\d+)?$/.test(formattedPrice)
    ? `${formattedPrice.replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`
    : "—";
};
