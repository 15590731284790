import { Calendar as CalendarIcon } from "@digitalatom/ui";
import React, { FC } from "react";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { colors, Error } from "../../styles";
import { today } from "../../utils/workingWithDates";
import * as elements from "./DateInput.styles";

export const DateInput: FC<PropsType> = (props) => {
  const { name, placeholder, label, minDate = null, maxDate = null } = props;
  const { disabled = false, color = colors.grayscaleInput } = props;

  const context = useFormContext();
  const { submitCount } = context.formState;

  const controller = useController({ name });
  const { field, fieldState } = controller;
  const { error: errorObj } = fieldState;
  const error = errorObj?.message;
  const { onBlur } = field;
  const { isTouched: touched } = fieldState;

  const setValue = (data: Date) => context.setValue(name, data);

  const value = useWatch({ name }) as Date;

  const { ContainerCalendar, Label, Calendar } = elements;
  const { PaperProps, InputAndIcon, Input, Icon } = elements;

  const hasError = (!!touched || submitCount) && !!error;

  const defaultCalendarMonth =
    maxDate && maxDate < today ? maxDate : minDate && minDate > today ? minDate : undefined;

  return (
    <ContainerCalendar>
      {label ? <Label>{label}</Label> : null}

      <Calendar
        defaultCalendarMonth={defaultCalendarMonth}
        label={placeholder}
        value={value || null}
        minDate={minDate}
        maxDate={maxDate}
        mask="__.__.____"
        inputFormat="dd.MM.yyyy"
        onChange={(newValue) => {
          context.trigger(name);
          setValue(newValue as Date);
        }}
        PaperProps={PaperProps}
        renderInput={(params) => (
          <InputAndIcon disabled={disabled}>
            <Input
              value={value}
              needlabel={+!!label}
              onBlur={onBlur}
              haserror={+hasError}
              {...params}
              name={name}
              autoComplete="off"
              backgroundcolor={color}
            />
            <Icon needlabel={+!!label}>
              <CalendarIcon />
            </Icon>
          </InputAndIcon>
        )}
        disabled={disabled}
      />

      {hasError ? <Error bottom={-14}>{error}</Error> : null}
    </ContainerCalendar>
  );
};

type PropsType = {
  name: string;
  placeholder: string;
  label?: string;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  color?: string;
};
