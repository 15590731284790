import styled from "@emotion/styled";
import { borders, colors, flex, margins, size, text } from "../../styles";

const { white, primaryDefault, grayscaleInput, grayscaleLine } = colors;
const { grayscaleIcons, grayscalePlaceholder, grayscaleBody } = colors;

export const Container = styled.div<{ disabled: boolean; withOutLable?: boolean }>`
  ${flex({ gap: 8, vertical: "center" })};
  ${({ withOutLable }) => (withOutLable ? "" : size({ h: 40 }))};
  ${({ withOutLable: withOutBorders }) =>
    withOutBorders ? "" : borders({ color: grayscaleInput, totalRadius: true })};
  ${({ withOutLable: withOutBorders }) => (withOutBorders ? "" : margins({ p: "8" }))};

  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  background: ${white};
`;

export const Switch = styled.div<{ checked: boolean }>`
  ${({ checked }) => flex({ horizontal: checked ? "right" : "left" })};
  ${size({ h: 24, w: 42 })};
  ${borders({ totalRadius: true })};
  ${margins({ p: "2" })};

  background: ${({ checked }) => (checked ? primaryDefault : grayscaleLine)};

  div {
    ${flex({ totalCenter: true })};
    ${size({ s: 20 })};
    ${borders({ totalRadius: true })};
    ${margins({ m: "0" })};

    background: ${white};

    & > svg > path {
      fill: ${grayscaleIcons};
    }
  }
`;

export const Label = styled.span<{ disabled: boolean }>`
  ${({ disabled }) =>
    text({
      size: 11,
      height: 16,
      weight: 700,
      color: disabled ? grayscalePlaceholder : grayscaleBody,
      align: "center",
      uppercase: true,
    })};
`;
