import { css } from "@emotion/react";
import { ColorsType, StatusColorsType } from "../api";
import { borders } from "./borderStyles";
import { colors } from "./colors";
import { size } from "./sizeStyles";

const { transparent, blackOpacity, grayscaleInput, grayscaleLine, grayscaleLabel } = colors;
const { primaryActiveElement, primaryDefaultDark, redBackground, redDefault } = colors;
const { greenBackground, greenDefault, warningBackground, warningDefault } = colors;

export const overflowOneLine = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const overflowLines = (lines: number) => css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: ${lines};
  -webkit-box-orient: vertical;
`;

export const shadow = `0 0 2px ${blackOpacity}, 0 2px 24px ${blackOpacity}`;

export const statusColors = {
  RED: { border: redBackground, background: redDefault },
  YELLOW: { border: warningBackground, background: warningDefault },
  BLUE: { border: primaryActiveElement, background: primaryDefaultDark },
  GREEN: { border: greenBackground, background: greenDefault },
  GRAY: { border: grayscaleInput, background: grayscaleLabel },
} as Record<StatusColorsType, { border: ColorsType; background: ColorsType }>;

export const scroll = css`
  overflow-y: scroll;

  &::-webkit-scrollbar {
    ${size({ w: 8 })};

    background: ${transparent};
  }

  &::-webkit-scrollbar-thumb {
    ${borders({ totalRadius: true })};
    background: ${grayscaleLine};
  }
`;

export const lifecycleStatusColors = {
  RED: statusColors.RED.border,
  YELLOW: warningBackground,
  BLUE: primaryActiveElement,
  GREEN: greenBackground,
  GRAY: statusColors.GRAY.background,
} as Record<StatusColorsType, string>;
