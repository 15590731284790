import styled from "@emotion/styled";
import { Checkbox } from "@mui/material";
import { colors, margins } from "../../styles";

const { grayscaleHoverBackground, grayscaleInput, grayscaleLabel } = colors;
const { primaryBackground, primaryDefault } = colors;

export const Container = styled(Checkbox)<{
  checked: boolean;
  disabled: boolean;
  fordisplay?: `${boolean}`;
}>`
  ${({ fordisplay }) => (fordisplay ? "pointer-events: none;" : "")}

  &.MuiCheckbox-root {
    ${margins({ p: "0" })};
  }

  & > svg > path {
    ${({ disabled }) => (disabled ? `fill: ${grayscaleInput}` : undefined)};
  }

  &:hover {
    background: ${({ checked }) => (checked ? primaryBackground : grayscaleHoverBackground)};
    ${({ disabled }) => (disabled ? `cursor: no-drop` : undefined)};

    & > svg > path {
      fill: ${({ checked }) => (checked ? primaryDefault : grayscaleLabel)};
    }
  }
`;
