import React from "react";
import { colors } from "../../styles";

export const BigPlus = (
  { size = 44 }: { size?: number } // todo: нельзя ли иначе поменять размер?
) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill={colors.grayscaleIcons} />
    <path
      d="M22 13.5C22.5523 13.5 23 13.9477 23 14.5L23 21H29.5C30.0523 21 30.5 21.4477 30.5 22C30.5 22.5523 30.0523 23 29.5 23H23L23 29.5001C23 30.0524 22.5523 30.5001 22 30.5001C21.4477 30.5001 21 30.0524 21 29.5001L20.999 23H14.5C13.9477 23 13.5 22.5523 13.5 22C13.5 21.4477 13.9477 21 14.5 21H20.999L21 14.5C21 13.9477 21.4477 13.5 22 13.5Z"
      fill="white"
    />
    <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" stroke={colors.grayscaleIcons} />
  </svg>
);
