import { Book, TextInputWithRHF } from "@digitalatom/ui";
import saveAs from "file-saver";
import React, { FC, useEffect, useState } from "react";
import {
  FieldValues,
  UseFieldArrayRemove,
  UseFieldArrayReplace,
  UseFormTrigger,
  UseFormUnregister,
  useWatch,
} from "react-hook-form";
import { DocumentForFormType, DocumentsForFormType } from "../../api";
import { FileLink } from "../../components";
import { fileSizeConversion } from "../../utils/fileSizeConversion";
import { OptionsType } from "../../utils/getOptions";
import { EditButton, OkButton, TrashButton } from "../Buttons";
import { SelectAutocompleteInput } from "../SelectAutocompleteInput";
import * as elements from "./DocumentsInput.styles";

export const DocumentItem: FC<PropsType> = (props) => {
  const { i, name, document, documents, trigger, unregister, replaceDocument } = props;
  const { removeDocument, options, optionsLabel, forEditForm, descriptionLabel } = props;

  const { id, file, type, description, name: documentName, comment, createdDate } = document;

  const [editing, setEditing] = useState<number>();

  const { Container, Text, Title, Description, FileBlock } = elements;
  const { LoadBar, TextInputWrapper, Buttons, Button } = elements;

  const parameters = comment ? JSON.parse(comment) : "";
  const accept = parameters.accept;
  const size = parameters.size;

  const fileName = useWatch({ name: `${name}.${i}.name` }) as string;

  const handleUpdateDocument = () => {
    setEditing(undefined);

    replaceDocument([
      ...documents.slice(0, i),
      {
        ...(id ? { id } : {}),
        file:
          file && typeof file !== "string"
            ? new File([file], fileName + accept, { type: file.type })
            : file,
        type,
        description,
        comment,
        createdDate,
        name: fileName,
      },
      ...documents.slice(i + 1),
    ]);

    trigger(`${name}.${i}.file`);
    trigger(`${name}.${i}.name`);
  };

  useEffect(() => {
    trigger(`${name}.${i}.name`);
  }, [document.name]);

  return (
    <FileBlock key={i}>
      <Container status="wasFile" little>
        <LoadBar status="loaded">
          <Book />
          {comment && accept ? accept : ""}
        </LoadBar>

        {editing === i ? (
          <>
            <div />
            <TextInputWrapper>
              <TextInputWithRHF name={`${name}.${i}.name`} />
            </TextInputWrapper>
          </>
        ) : (
          <Text>
            {id !== "" && typeof file === "string" ? (
              <Title status="wasFile">
                <FileLink file={file} name={`${documentName}${accept}`} />
              </Title>
            ) : file ? (
              <Title
                status="wasFile"
                onClick={() =>
                  comment && accept ? saveAs(file, `${documentName}${accept}`) : undefined
                }
                needDownload
              >
                {documentName}
              </Title>
            ) : null}

            <Description status="wasFile">
              {comment && size ? `${fileSizeConversion(size)}` : ""}
            </Description>
          </Text>
        )}

        <Buttons>
          {forEditForm || editing === i ? (
            <Button />
          ) : (
            <TrashButton
              onClick={() => {
                removeDocument(i);
                unregister(`${name}.${i}.description`);
              }}
            />
          )}

          {editing === i ? (
            <OkButton onClick={handleUpdateDocument} />
          ) : (
            <EditButton onClick={() => setEditing(i)} />
          )}
        </Buttons>
      </Container>

      {options && (
        <SelectAutocompleteInput
          name={`${name}.${i}.type`}
          options={options}
          label={optionsLabel ?? ""}
        />
      )}

      {descriptionLabel && (
        <TextInputWithRHF name={`${name}.${i}.description`} label={descriptionLabel} multiline />
      )}
    </FileBlock>
  );
};

type PropsType = {
  i: number;
  document: DocumentForFormType;
  documents: DocumentsForFormType | [];
  trigger: UseFormTrigger<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
  replaceDocument: UseFieldArrayReplace<FieldValues, string>;
  removeDocument: UseFieldArrayRemove;
  name: string;
  forEditForm: boolean;
  options?: OptionsType;
  optionsLabel?: string;
  descriptionLabel?: string;
};
