import { ArrowLeft } from "@digitalatom/ui";
import React, { FC } from "react";
import { To, useNavigate } from "react-router-dom";
import { Container } from "./NavigateButton.styles";

export const NavigateButton: FC<PropsType> = (props) => {
  const { link, title, onClick } = props;

  const navigate = useNavigate();

  return (
    <Container
      onClick={() => {
        link && navigate(link as To);
        onClick && onClick();
      }}
    >
      <ArrowLeft />
      <p>{title}</p>
    </Container>
  );
};

type PropsType = {
  link?: string | -1;
  onClick?: () => void;
  title: string;
};
