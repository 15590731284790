import { TriangleDown, TriangleRight, TriangleUp } from "@digitalatom/ui";
import React, { FC } from "react";
import { Container } from "./OpenListButton.styles";

export const OpenListButton: FC<PropsType> = (props) => {
  const { isOpen, openRight = false, onClick, disabled = false } = props;

  return (
    <Container type="button" onClick={onClick} isOpen={isOpen} disabled={disabled}>
      {isOpen ? openRight ? <TriangleRight /> : <TriangleUp /> : <TriangleDown />}
    </Container>
  );
};

type PropsType = {
  isOpen: boolean;
  openRight?: boolean;
  onClick?: () => void;
  disabled?: boolean;
};
