import { EntityNameType } from "../api";

/**
 *
 * ------------------------------------------------------------------------------------------
 * **ПРОВЕРКА НАЛИЧИЯ КЛЮЧА В ОБЪЕКТЕ**
 *
 * *Функция проверяет, присутствует ли указанный ключ в объекте*
 *
 * *Данная функция полезна при работе с объектами, у которых ключи определяются динамически, и необходимо строго проверить наличие определённого ключа, а также уточнить тип объекта при последующих операциях*
 *
 * @param obj - объект для проверки. Является словарём, где ключи — строки, а значения — строки или объекты типа OBJ
 * @param entityName - ключ, наличие которого нужно проверить в `obj`
 * @returns булево значение, указывающее на наличие ключа `entityName` в `obj`
 *
 * @description
 * *Особенности*:
 * - Если `true`, TypeScript будет рассматривать `obj` как `Record<EntityNameType, string | O>`.
 *
 * *Примеры использования*:
 * - Применение фетч-плана для конкретного эндпоинта
 * - Применение условий сортировки для конкретного эндпоинта
 *
 */

export const checkKey = <OBJ>(
  obj: Record<string, string | OBJ>,
  entityName: EntityNameType
): obj is Record<EntityNameType, string | OBJ> => Object.keys(obj).includes(entityName);
