import Keycloak from "keycloak-js";
import { getBackendURI } from "../utils/getBackendURI";

export const keycloakInstance = Keycloak({
  url: process.env.REACT_APP_MR_URL
    ? "http://medtech-keycloak:9000/auth/"
    : `https://auth.${getBackendURI()}/auth/`,
  realm: process.env.REACT_APP_MR_URL ? "medtech-realm" : "medtech-keycloak",
  clientId: "web-client",
});
