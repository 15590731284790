import { ArrowRound, ExclamationMark } from "@digitalatom/ui";
import React, { FC, RefObject } from "react";
import * as elements from "./DocumentsInput.styles";

export const InputError: FC<PropsType> = (props) => {
  const { element, fileNames, error, handleRemove } = props;

  const { Container, Title, Description, Text, Button, LoadBar, Buttons } = elements;

  return (
    <Container status="error" as="div">
      <LoadBar status="error">
        <ExclamationMark />
      </LoadBar>

      <Text>
        <Title status="error">{fileNames(element)}</Title>
        <Description status="error">{error}</Description>
      </Text>

      <Buttons>
        <div></div>

        <Button onClick={() => handleRemove(element)}>
          <ArrowRound />
        </Button>
      </Buttons>
    </Container>
  );
};

type PropsType = {
  element: RefObject<HTMLInputElement>;
  fileNames: (element?: RefObject<HTMLInputElement>) => string;
  error: string;
  handleRemove: (ref: RefObject<HTMLInputElement>) => void;
};
