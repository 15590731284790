import React, { FC } from "react";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { Label, Score, Wrapper } from "./NumberScoreInput.styles";

export const NumberScoreInput: FC<PropsType> = (props) => {
  const { name, from, to, lable } = props;

  const controller = useController({ name });
  const { field, fieldState } = controller;
  const { error: errorObj } = fieldState;
  const error = errorObj?.message;
  const { ref, onChange, onBlur } = field;
  const register = { ref, name, onChange, onBlur };

  const context = useFormContext();
  const setValue = (data: number) => context.setValue(name, data);

  const value = useWatch({ name }) as number;
  const hasError = !!error;

  const countOfScore = to - from + 1;
  const arrayOfScores = Array(countOfScore)
    .fill(undefined)
    .map((_, id) => from + id);

  return (
    <Wrapper hasError={hasError} {...register}>
      {lable && <Label>{lable}</Label>}
      {arrayOfScores.map((score) => (
        <Score selected={value === score} key={score} onClick={() => setValue(score)}>
          {score}
        </Score>
      ))}
    </Wrapper>
  );
};

type PropsType = {
  lable?: string;
  name: string;
  from: number;
  to: number;
};
