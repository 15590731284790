import { DateSwitcherTypeType } from "../atoms";

export const arrYears = ["год", "года", "лет"];
export const arrFromYears = ["года", "лет", "лет"];
export const arrMonths = ["месяц", "месяца", "месяцев"];
export const arrDays = ["день", "дня", "дней"];
export const arrDays2 = ["дня", "дней", "дней"];

export const periodName = {
  year: "Год",
  quarter: "Квартал",
  month: "Месяц",
  period: "Период",
} as Record<DateSwitcherTypeType, string>;
