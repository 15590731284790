import React from "react";
import { colors } from "../../styles";

export const Star = (
  { active }: { active: boolean } // todo: кажется иконка не должна знать о состояниях, надо как-то иначе
) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.1533 14.3398L22.3266 16.6864C22.4866 17.0131 22.9133 17.3264 23.2733 17.3864L25.3999 17.7398C26.7599 17.9664 27.0799 18.9531 26.0999 19.9264L24.4466 21.5798C24.1666 21.8598 24.0133 22.3998 24.0999 22.7864L24.5733 24.8331C24.9466 26.4531 24.0866 27.0798 22.6533 26.2331L20.6599 25.0531C20.2999 24.8398 19.7066 24.8398 19.3399 25.0531L17.3466 26.2331C15.9199 27.0798 15.0533 26.4464 15.4266 24.8331L15.8999 22.7864C15.9866 22.3998 15.8333 21.8598 15.5533 21.5798L13.8999 19.9264C12.9266 18.9531 13.2399 17.9664 14.5999 17.7398L16.7266 17.3864C17.0799 17.3264 17.5066 17.0131 17.6666 16.6864L18.8399 14.3398C19.4799 13.0664 20.5199 13.0664 21.1533 14.3398Z"
      fill={active ? colors.warningDefault : undefined}
      stroke={active ? undefined : colors.grayscaleLine}
      strokeWidth={active ? undefined : "1.5"}
      strokeLinecap={active ? undefined : "round"}
      strokeLinejoin={active ? undefined : "round"}
    />
  </svg>
);
