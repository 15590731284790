import { CheckMark, Trash } from "@digitalatom/ui";
import React, { FC } from "react";
import { PhotosType } from "../../api";
import { Photo } from "../../components/Photo";
import { Buttons, CheckButton, Photos, RemoveButton } from "./PhotosInput.styles";

export const PhotoList: FC<PropsType> = (props) => {
  const { photos, setValue } = props;

  const handleMain = (i: number) => {
    const data = photos.map((photo, j) => ({ ...photo, isMain: i === j && !photo.isMain }));
    setValue(data);
  };

  const handleRemove = async (id: string, i: number) => {
    const filteredPhotos = photos.filter((_, j) => i !== j);
    setValue(filteredPhotos);
  };

  if (!photos || photos.length === 0) return null;

  return (
    <Photos>
      {photos.map(({ id, file, isMain }, i) => (
        <Photo key={i} file={file} height={117}>
          <Buttons>
            <CheckButton onClick={() => handleMain(i)} check={isMain}>
              <CheckMark />
            </CheckButton>

            <RemoveButton onClick={() => handleRemove(id, i)}>
              <Trash />
            </RemoveButton>
          </Buttons>
        </Photo>
      ))}
    </Photos>
  );
};

type PropsType = {
  photos: PhotosType;
  setValue: (data: PhotosType) => void;
};
