import { ListPlus } from "@digitalatom/ui";
import React, { FC } from "react";
import { Container } from "./OpenFormButton.styles";

export const OpenFormButton: FC<PropsType> = (props) => {
  const { onClick } = props;

  return (
    <Container onClick={onClick}>
      <ListPlus />
    </Container>
  );
};

type PropsType = {
  onClick: () => void;
};
