import styled from "@emotion/styled";
import * as styles from "../../../styles";

const { borders, colors, flex, grid: gridStyles, margins, overflowOneLine, text, size } = styles;

const { white, grayscaleInput, grayscaleLabel, grayscaleOffWhite } = colors;

export const Container = styled.div<ContainerPropsType>`
  ${({ grid }) => gridStyles({ columns: grid })};
  ${size({ min: { w: 0, h: 64 } })};
  ${borders({ side: "bottom", color: grayscaleInput })};

  position: sticky;
  top: 0;
  background: ${grayscaleLabel};
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  z-index: 2;
`;

export const TableHeadItem = styled.div<{ width?: number }>`
  ${flex({ gap: 14 })};
  ${({ width }) => size({ ...(width ? { w: width } : {}), min: { w: 0 } })};
  ${borders({ side: "right", color: grayscaleInput })};
  ${margins({ p: "12 2 12 20" })};

  overflow: hidden;

  &.check,
  &.menu {
    ${flex({ totalCenter: true })};
    ${margins({ p: "0" })};
  }

  &:last-of-type {
    ${borders({ none: true })};
  }

  svg > path {
    fill: ${white};
  }
`;

export const HeaderTitleText = styled.p`
  ${overflowOneLine};
  ${text({ size: 17, height: 24, weight: 700, color: grayscaleOffWhite })};
`;

export const CommonTitleText = styled(HeaderTitleText)`
  ${margins({ p: "12 2 12 20" })};
  ${borders({ side: "bottom", color: grayscaleInput })};
`;

export const CommonTitlesWrapper = styled.div<{ gridTemplateColumns: string }>`
  ${({ gridTemplateColumns }) => gridStyles({ columns: gridTemplateColumns })}
`;

export const WithCommonTitleHeaderBlock = styled.div`
  ${flex({ isColumn: true })}
`;

type ContainerPropsType = {
  needCheck: boolean;
  needMenu: boolean;
  grid: string;
};
