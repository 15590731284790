import { DateRange } from "@mui/lab";
import { atom, Getter } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable, useAtomValue } from "jotai/utils";
import {
  applicationsApi,
  ApplicationType,
  BooleanType,
  registriesApi,
  registriesTravelPackageApi,
} from "../../api";
import { fetchAll, fetchById } from "../../utils/fetchData";
import { getPaginationQueryKeys, usePaginationQueryKeys } from "../../utils/useQueryKeys";
import { formatDate } from "../../utils/workingWithDates";
import { currentDateInterval, registryIdState } from "../index";

export const rejectApplicationState = atom<ApplicationType | undefined>(undefined);
export const rejectConfirmState = atom<string | undefined>(undefined);

// ------------------------------ ФИЛЬТРЫ

export const applicationsInRegistryAbsoluteInitialValues: ApplicationsInRegistryFiltersType = {
  department: "",
  containsRehab: "",
  medicalPrograms: [],
  sanatorium: "",
  since: null,
  until: null,
  selectOnlyCorrected: false,
};

export const applicationsInRegistryFiltersState = atom<ApplicationsInRegistryFiltersType>(
  applicationsInRegistryAbsoluteInitialValues
);

export type ApplicationsInRegistryFiltersType = {
  department: string;
  containsRehab: BooleanType | "";
  medicalPrograms: string[];
  sanatorium: string;
  since: Date | null;
  until: Date | null;
  selectOnlyCorrected: boolean;
};

// ------------------------------ ОБЩИЕ КЛЮЧИ ДЛЯ РЕЕСТРА ЗАЯВОК И ПУТЕВОК

const getRegistriesStatesValues = (get: Getter) => {
  const { size, startIndex, query } = getPaginationQueryKeys(get);
  const registry = get(registryIdState);
  const filters = get(applicationsInRegistryFiltersState);

  return {
    query,
    registry,
    filters,
    size,
    startIndex,
  };
};

// ------------------------------ ЗАЯВКИ В РЕЕСТРЕ

const registryApplicationsAtom = atomWithQuery((get) => {
  const args = getRegistriesStatesValues(get);

  return registryApplicationsQuery(args);
});

export const registryApplicationsState = loadable(registryApplicationsAtom);

const registryApplicationsQuery = (props: PropsType) => {
  const { registry } = props;

  const { keys, args } = getArgs(props);

  return {
    queryKey: [...keys, "registryApplicationsState"],
    queryFn: () => fetchAll(() => applicationsApi().getFiltered(args)),
    enabled: !!registry && registry !== "",
    keepPreviousData: true,
  };
};

// ------------------------------ ВЫЧИСЛЕНИЕ АРГУМЕНТОВ

export const useRegistryApplicationsKeys = () => {
  const { size, startIndex, query } = usePaginationQueryKeys();
  const registry = useAtomValue(registryIdState);
  const filters = useAtomValue(applicationsInRegistryFiltersState);

  const args = { size, startIndex, query, registry, filters };

  return getArgs(args).keys;
};

export const getArgs = (props: PropsType) => {
  const { startIndex, size, query, registry, filters, dateInterval } = props;

  const sanatoriumId = filters.sanatorium || undefined;
  const department = filters.department || undefined;
  const containsRehab = filters.containsRehab || undefined;
  const medicalProgramIds =
    filters.medicalPrograms.length && containsRehab === "true"
      ? filters.medicalPrograms.map((uuid) => ({ uuid }))
      : undefined;
  const corrected = filters.selectOnlyCorrected || undefined;

  const since = filters.since
    ? formatDate({ date: filters.since, type: "forBackend" })
    : dateInterval?.[0]
    ? formatDate({ date: dateInterval[0], type: "forBackend" })
    : undefined;
  const until = filters.until
    ? formatDate({ date: filters.until, type: "forBackend" })
    : dateInterval?.[1]
    ? formatDate({ date: dateInterval[1], type: "forBackend" })
    : undefined;

  const args = {
    startIndex,
    size,
    registry,
    filter: {
      query,
      sanatoriumId,
      department,
      containsRehab,
      medicalProgramIds,
      corrected,
      since,
      until,
    },
  };

  const keys = [
    startIndex,
    size,
    query,
    registry,
    JSON.stringify(filters),
    JSON.stringify(dateInterval),
  ];

  return { keys, args };
};

// ------------------------------ ТЕКУЩИЙ РЕЕСТР ЗАЯВОК

const registryAtom = atomWithQuery((get) => {
  const registryId = get(registryIdState);

  return registryQuery(registryId);
});

export const registryState = loadable(registryAtom);

const registryQuery = (registryId: string) => ({
  queryKey: [registryId, "registryAtom"],
  queryFn: () => fetchById(() => registriesApi().getRegistryWithTask(registryId)),
  enabled: !!registryId,
});

// ------------------------------ ТЕКУЩИЙ РЕЕСТР ПУТЕВОК

const registryTravelPackagesAtom = atomWithQuery((get) => {
  const id = get(registryIdState);

  return {
    queryKey: [id, "registryTravelPackagesState"],
    queryFn: () => fetchById(() => registriesTravelPackageApi().getRegistryWithTask(id)),
    enabled: !!id,
  };
});

export const registryTravelPackagesState = loadable(registryTravelPackagesAtom);

// ------------------------------ ЗАЯВКИ В РЕЕСТРЕ ПУТЕВОК

const registryTravelPackageApplicationsAtom = atomWithQuery((get) => {
  const args = getRegistriesStatesValues(get);
  const dateInterval = get(currentDateInterval);

  return registryTravelPackageApplicationsQuery({ ...args, dateInterval });
});

export const registryTravelPackageApplicationsState = loadable(
  registryTravelPackageApplicationsAtom
);

const registryTravelPackageApplicationsQuery = (props: PropsType) => {
  const { registry } = props;

  const { keys, args } = getArgs(props);

  return {
    queryKey: [...keys, "registryTravelPackageApplicationsState"],
    queryFn: () => fetchAll(() => applicationsApi().getFiltered(args, true)),
    enabled: !!registry && registry !== "",
    keepPreviousData: true,
  };
};

type PropsType = {
  startIndex: number;
  size: number;
  query?: string;
  registry: string;
  filters: ApplicationsInRegistryFiltersType;
  dateInterval?: DateRange<Date>;
};
