import styled from "@emotion/styled";
import { Dialog } from "@mui/material";
import { borders, colors, flex, margins, size, text } from "../../styles";

const { white, grayscaleLabel, primaryActiveElementOpacity } = colors;

export const Backdrop = styled(Dialog)`
  ${borders({ radius: 24 })};

  .MuiBackdrop-root {
    background: ${primaryActiveElementOpacity};
    backdrop-filter: blur(10px);
    opacity: 0.6;
  }

  .MuiPaper-root {
    ${flex({ gap: 16, isColumn: true, horizontal: "center" })};
    ${borders({ radius: 24 })};

    min-height: 151px;
    box-shadow: none;
  }
` as typeof Dialog;

export const ModalContent = styled.div`
  ${flex({ gap: 16, isColumn: true, horizontal: "center" })};
  ${size({ w: 360 })};
  ${borders({ radius: 24 })};
  ${margins({ p: "16" })};

  position: relative;
  background: ${white};
  z-index: 99;
`;

export const LoaderContainer = styled.div`
  ${flex({ horizontal: "center", vertical: "center" })};
  ${size({ h: 158 })};
`;

export const Text = styled.p`
  ${size({ w: "100%" })};
  ${text({ size: 20, height: 26, weight: 700, align: "center" })};
  white-space: pre-line;
`;

export const Description = styled.p`
  ${size({ w: "100%" })};
  ${text({ size: 15, height: 20, weight: 400, color: grayscaleLabel, align: "center" })};
`;
