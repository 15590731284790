/**
 *
 * ------------------------------------------------------------------------------------------
 * **НЕОБХОДИМОСТЬ ПАГИНАЦИИ**
 *
 * *Функция проверяет, нужно ли включать пагинацию, исходя из общего количества элементов*
 *
 * *Если общее количество элементов не указано или меньше либо равно 1, пагинация не нужна*
 *
 * @param totalCount - общее количество элементов
 * @returns булево значение
 *
 */

export const pagesMoreOne = (totalCount: number | undefined) => !!totalCount && totalCount > 1;
