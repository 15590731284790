import React, { FC } from "react";
import { CloseButton } from "../Buttons";
import { Container, Content, Title, TitleAndCloseButton } from "./CenteredModal.styles";

export const CenteredModal: FC<PropsType> = (props) => {
  const { isOpen = false, forForm = false, needCloseButton = false } = props;
  const { width, onClose } = props;
  const { title, footer, children } = props;

  return (
    <Container
      open={isOpen}
      onClose={onClose}
      width={width}
      forform={+forForm}
      closeAfterTransition={false}
    >
      {(title || needCloseButton) && (
        <TitleAndCloseButton>
          {typeof title === "string" ? (
            <>
              <Title forform={+forForm}>{title}</Title>

              {forForm ? null : <CloseButton onClick={onClose} type="crossOnCircle" />}
            </>
          ) : (
            <>
              {title}

              {needCloseButton && <CloseButton onClick={onClose} type="crossOnCircle" />}
            </>
          )}
        </TitleAndCloseButton>
      )}

      <Content>{children}</Content>
      {footer}
    </Container>
  );
};

type PropsType = {
  title?: string | JSX.Element;
  footer?: JSX.Element;
  isOpen?: boolean;
  width: number | `${number}%` | "min";
  onClose: () => void;
  forForm?: boolean;
  needCloseButton?: boolean;
};
