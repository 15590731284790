import { SyntheticEvent } from "react";

/**
 *
 * ------------------------------------------------------------------------------------------
 * **ПРЕДОТВРАЩЕНИЕ СТАНДАРТНОГО ПОВЕДЕНИЯ И СОБЫТИЙ**
 *
 * *Функция предотвращает стандартное поведение браузера и дальнейшую передачу события по цепочке обработчиков*
 *
 * @param evt - событие, которое необходимо предотвратить
 *
 */

export const preventDefault = (evt: SyntheticEvent) => {
  evt.preventDefault();
  evt.stopPropagation();
};
