import { Person } from "@digitalatom/ui";
import { useAtomValue } from "jotai/utils";
import React, { useState } from "react";
import { selectedRoomsState, vacationersState } from "../../../../../atoms";
import { PopoverModal } from "../../../../../uiKit";
import { ErrorText, InputBlock, InputText } from "./PersonDropdownForm.styles";
import { VacationersModal } from "./VacationersModal/VacationersModal";

export const VacationersDropdownForm = () => {
  const selectedVacationers = useAtomValue(vacationersState);

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

  const selectedRooms = useAtomValue(selectedRoomsState);

  const { filterText } = selectedVacationers;

  const isEmployeeSelected = !!selectedRooms.find(({ vacationers }) =>
    vacationers.adults.find((adult) => adult === "EMPLOYEE")
  );

  const isEmployeeError = !!selectedRooms.length && !isEmployeeSelected;

  const handleCloseMenu = () => setAnchorElement(null);

  return (
    <div>
      <InputBlock hasError={isEmployeeError} onClick={(evt) => setAnchorElement(evt.currentTarget)}>
        <Person />
        <InputText placeholder={filterText}>{filterText ? filterText : "Гости"}</InputText>
        {isEmployeeError && <ErrorText>Выберите номер для работника</ErrorText>}
      </InputBlock>

      <PopoverModal
        id="vacationersMenu"
        isOpen={!!anchorElement}
        element={anchorElement}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "center", horizontal: "left" }}
        transformOrigin={{ vertical: -36, horizontal: "left" }}
        maxHeight={361}
      >
        <VacationersModal onClose={handleCloseMenu} />
      </PopoverModal>
    </div>
  );
};
