/**
 *
 * ------------------------------------------------------------------------------------------
 * **ПОЛУЧЕНИЕ МОДИФИЦИРОВАННОГО URI ДЛЯ БЭКЕНДА**
 *
 * *Функция извлекает текущий хост из `window.location.host` и при определённых условиях модифицирует его*
 *
 * *Если хост содержит 4 сегмента, первый сегмент удаляется, а оставшиеся сегменты вновь соединяются в строку*
 *
 * *В остальных случаях возвращается исходный хост без изменений*
 *
 * @returns Модифицированный или исходный URI
 *
 */

export const getBackendURI = () => {
  const URI = window.location.host;
  const splittedURI = URI.split(".");

  return splittedURI.length === 4 ? splittedURI.shift() && splittedURI.join(".") : URI;
};
