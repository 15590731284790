import React, { FC } from "react";
import { colors } from "../../styles";

export const InfoFilled: FC<{ size?: number }> = (
  { size = 20 } // todo: нельзя ли иначе поменять размер?
) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM9 14V10C9 9.44772 9.44772 9 10 9C10.5523 9 11 9.44772 11 10V14C11 14.5523 10.5523 15 10 15C9.44772 15 9 14.5523 9 14ZM8.75016 6.24998V5.7502C8.75016 5.19791 9.19787 4.7502 9.75016 4.7502H10.25C10.8023 4.7502 11.25 5.19791 11.25 5.7502V6.24998C11.25 6.80226 10.8023 7.24998 10.25 7.24998H9.75016C9.19787 7.24998 8.75016 6.80226 8.75016 6.24998Z"
      fill={colors.grayscaleIcons}
    />
  </svg>
);
