import { AxiosResponse } from "axios";
import { hrApi, hrImportApi } from "./api";
import { PricesType } from "./prices";
import { entitiesBase } from "./request";
import { RoomWithAccommodationType } from "./rooms";
import { AccommodationCategoryType, AccommodationTypeType, BackendDateType, IdType } from "./types";

// ------------------------------ РАЗМЕЩЕНИЯ

export const accommodationsApi = <R>() =>
  entitiesBase<RoomWithAccommodationType, R, undefined>(hrApi, "resort_Accommodation");

export const exportAccommodationsApi = {
  upload: (props: FormData): Promise<AxiosResponse<ExportAccommodationType>> =>
    hrImportApi.post("/accommodations", props, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data; boundary=WebAppBoundary",
      },
    }),
};

// ------------------------------ ТИПЫ - РАЗМЕЩЕНИЯ

export type AccommodationType = IdType & {
  room?: string | RoomWithAccommodationType;
  type: AccommodationTypeType;
  category: AccommodationCategoryType | "";
  ageSince: number;
  ageUntil: number;
  numberCondition: number | null;
  prices: PricesType;
};

// ------------------------------ ТИПЫ - EXPORT ACCOMMODATIONS

export type ExportAccommodationPeriodType = {
  cellNumber: number;
  value: string;
  error: string | null;
  overlappingPeriods: { value: BackendDateType }[] | null;
  since: BackendDateType | null;
  until: BackendDateType | null;
  warning: string | null;
};

type ExportAccommodationRoomType = {
  rea: boolean;
  rehab: "СКЛ" | "СКЛ/РОМ"; // todo: точно может быть "СКЛ"?
  value: string;
  accommodations: {
    category: string;
    error: null;
    type: string;
    value: string;
    prices: {
      error: string | null;
      since: BackendDateType | null;
      until: BackendDateType | null;
      value: number;
    }[];
  }[];
};

// ----- получаемые данные

export type AccommodationsType = AccommodationType[];

export type ExportAccommodationType = {
  periods: ExportAccommodationPeriodType[];
  rooms: ExportAccommodationRoomType[];
};
