import produce from "immer";
import { useAtom } from "jotai";
import { MouseEvent, ReactNode, useState } from "react";
import { selectedColumnsState, selectedRowsState } from "../../atoms";
import { nonEditableIds } from "../../constants/ids";

export const useTable = <OBJ extends Record<string, unknown>>() => {
  // ------------------------------ АТОМЫ

  const [selectedRows, setSelectedRows] = useAtom(selectedRowsState);
  const [selectedColumns, setSelectedColumns] = useAtom(selectedColumnsState);

  // ------------------------------ СТЕЙТЫ

  const [columnSettingsButton, setColumnSettingsButton] = useState<HTMLElement | undefined>();
  const [anchorHeader, setAnchorHeader] = useState<HTMLElement | undefined>();
  const [hintText, setHintText] = useState<string | undefined>();
  const [anchorMenuButton, setAnchorMenuButton] = useState<HTMLElement | undefined>();
  const [openMenuId, setOpenMenuId] = useState<string | undefined>();
  const [confirmModalContent, setConfirmModalContent] = useState<
    DeletionModalContentType | undefined
  >();

  // ------------------------------ ЯВЛЯЕТСЯ ЛИ СТРОКА НЕ РЕДАКТИРУЕМОЙ

  const nonEditableId = (id: string) => nonEditableIds.includes(id);

  // ------------------------------ ВЫБОР ВСЕХ СТРОК

  const selectAllRows = (ids: string[]) => {
    const newIds = ids.filter((id) => !nonEditableId(id));

    setSelectedRows(!!newIds && selectedRows.length === 0 ? newIds : []);
  };

  // ------------------------------ ВЫБРАНА ЛИ СТРОКА

  const isRowSelected = (identifier: string) => selectedRows.includes(identifier);

  // ------------------------------ ВЫБОР СТРОК

  const selectRows = (identifier: string) => {
    const isSelected = isRowSelected(identifier);

    isSelected
      ? setSelectedRows((draft) => draft.filter((item) => identifier !== item))
      : setSelectedRows([...selectedRows, identifier]);
  };

  // ------------------------------ ВЫБРАН ЛИ СТОЛБЕЦ

  const isColumnSelected = (number: number) => selectedColumns.includes(number);

  // ------------------------------ ВЫБОР СТОЛБЦОВ

  const selectColumns = (number: number, setUserSettings?: (arg: number[]) => void) => {
    const index = selectedColumns.findIndex((row) => row === number);

    const newSelectedColumns = produce(selectedColumns, (draft) => {
      index === -1 ? draft.push(number) : draft.splice(index, 1);
    });

    setSelectedColumns(newSelectedColumns);

    setUserSettings && setUserSettings(newSelectedColumns);
  };

  // ------------------------------ ФОРМАТИРОВАНИЕ ДАННЫХ ДЛЯ ДОБАВЛЕНИЯ В СТРОКИ

  const columnsNames = (titlesInit: TitlesType) =>
    titlesInit
      .filter(({ id }) => selectedColumns.includes(id))
      .flatMap((title) => ("commonTitle" in title ? title.titles : title))
      .map(({ name }) => name);

  const formatData = (
    object: OBJ,
    titlesInit: TitlesType
  ): Record<string, string | string[] | number | undefined> => {
    return Object.keys(object)
      .filter((key) => columnsNames(titlesInit).includes(key) || key === "colors")
      .reduce((obj, key) => ({ ...obj, [key]: object[key as keyof typeof object] }), {});
  };

  // ------------------------------ ФОРМАТИРОВАНИЕ ШИРИН СТОЛБЦОВ ДЛЯ ВСТАВКИ В СТИЛИ

  function sumSizes(sizes: GridsType[number]["size"]) {
    if (typeof sizes === "string") return sizes;

    const sizeRegex = /^(\d+)([a-zA-Z%]+)$/;
    const sums: Record<string, number> = {};

    sizes.forEach(({ size }) => {
      const match = size.match(sizeRegex);
      if (match) {
        const value = parseInt(match[1], 10);
        const unit = match[2];

        if (!sums[unit]) {
          sums[unit] = 0;
        }
        sums[unit] += value;
      }
    });
    const result = Object.keys(sums).map((unit) => `${sums[unit]}${unit}`);
    return result.join(" ");
  }

  const getGrids = (gridInit: GridsType, forHeader?: boolean) =>
    gridInit
      .map(({ size }) => {
        if (typeof size === "string") return size;
        return forHeader ? sumSizes(size) : size.map((size) => size.size).join(" ");
      })
      .join(" ");

  // ------------------------------ ФИЛЬТРАЦИЯ ТОЛЬКО ВЫБРАННЫХ СТОЛБЦОВ

  const getTitles = (titlesInit: TitlesType) =>
    titlesInit.filter(({ id }) => selectedColumns.includes(id));

  // ------------------------------ ПОДСКАЗКИ К ЗАГОЛОВКАМ СТОЛБЦОВ

  const openHint = ({ currentTarget }: MouseEvent<HTMLElement>, allIdsNonEditable: boolean) => {
    const isMenu = currentTarget.className?.includes("menu");
    const isCheck = currentTarget.className?.includes("check");
    const text = currentTarget.getElementsByTagName("p")[0];
    const innerHTML = text?.innerHTML;

    const isTextOverflow = text?.scrollWidth > text?.clientWidth;

    if (!isTextOverflow && !isMenu && !isCheck) return;

    setAnchorHeader(currentTarget);
    setHintText(
      isMenu
        ? "Выбор отображаемых столбцов"
        : isCheck
        ? allIdsNonEditable
          ? "Удаление невозможно: все данные на этой странице связаны с критической функцией системы"
          : "Выбор всех строк"
        : innerHTML
    );
  };

  const closeHint = () => {
    setAnchorHeader(undefined);
    setHintText(undefined);
  };

  // ------------------------------ ВЫЗОВ МЕНЮ НАСТРОЙКИ СТОЛБЦОВ

  // todoLater: подумать о возможности смены порядка столбцов

  const openColumnSettings = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    setColumnSettingsButton(evt.currentTarget);
  };

  const closeColumnSettings = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    setColumnSettingsButton(undefined);
  };

  // ------------------------------ ВЫЗОВ МЕНЮ СТРОКИ

  const openMenu = (evt: MouseEvent<HTMLElement>, id: string) => {
    evt.stopPropagation();
    setAnchorMenuButton(evt.currentTarget);
    setOpenMenuId(id);
  };

  const closeMenu = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    setAnchorMenuButton(undefined);
    setOpenMenuId(undefined);
  };

  return {
    selectAllRows,

    nonEditableId,

    isRowSelected,
    selectRows,
    selectedRows,
    setSelectedRows,

    isColumnSelected,
    selectColumns,
    setSelectedColumns,

    formatData,

    getGrids,
    getTitles,

    anchorHeader,
    openHint,
    closeHint,
    hintText,

    columnSettingsButton,
    openColumnSettings,
    closeColumnSettings,

    anchorMenuButton,
    openMenuId,
    openMenu,
    closeMenu,

    confirmModalContent,
    setConfirmModalContent,
  };
};

export type GridsType = {
  titleId: number | null;
  size:
    | string
    | {
        titleId: number;
        size: string;
      }[];
}[];

export type TitlesType = TitleType[];

type TitleType =
  | {
      id: number;
      title: string;
      name: string;
      icon?: ReactNode;
      iconPosition?: "left" | "right";
    }
  | {
      commonTitle: ReactNode;
      name: string;
      id: number;
      titles: {
        id: number;
        title: string;
        name: string;
        icon?: ReactNode;
        iconPosition?: "left" | "right";
      }[];
    };

export type DeletionModalContentType = {
  questionText: string;
  description?: string;
  deletion: () => Promise<unknown[]>;
};

export type TableMethodsType<OBJ> = {
  selectAllRows: (ids: string[]) => void;

  nonEditableId: (id: string) => boolean;

  isRowSelected: (identifier: string) => boolean;
  selectRows: (identifier: string) => void;
  selectedRows: string[];
  setSelectedRows: (arg: string[]) => void;

  isColumnSelected: (number: number) => boolean;
  selectColumns: (number: number, setUserSettings?: (arg: number[]) => void) => void;
  setSelectedColumns: (update: number[]) => void;

  formatData: (
    object: OBJ,
    titlesInit: TitlesType
  ) => Record<string, string | string[] | number | undefined>;

  getGrids: (gridInit: GridsType) => string;
  getTitles: (titlesInit: TitlesType) => TitlesType;

  anchorHeader: HTMLElement | undefined;
  openHint: (evt: MouseEvent<HTMLElement>, allIdsNonEditable: boolean) => void;
  closeHint: () => void;
  hintText: string | undefined;

  columnSettingsButton: HTMLElement | undefined;
  openColumnSettings: (evt: MouseEvent<HTMLElement>) => void;
  closeColumnSettings: (evt: MouseEvent<HTMLElement>) => void;

  anchorMenuButton: HTMLElement | undefined;
  openMenuId: string | undefined;
  openMenu: (evt: MouseEvent<HTMLElement>, id: string) => void;
  closeMenu: (evt: MouseEvent<HTMLElement>) => void;

  confirmModalContent: DeletionModalContentType | undefined;
  setConfirmModalContent: (args: DeletionModalContentType) => void;
};
