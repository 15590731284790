import { ThreeDots } from "@digitalatom/ui";
import React, { FC, MouseEvent, useState } from "react";
import { PopoverHover } from "../..";
import { Cross } from "../../Icons";
import { Container } from "./TableMenuButton.styles";

export const TableMenuButton: FC<PropsType> = (props) => {
  const { menuModalIsOpen, fromCard = false, onClick, disabled = false } = props;

  const [anchorButton, setAnchorButton] = useState<HTMLElement | undefined>();

  const openHint = ({ currentTarget }: MouseEvent<HTMLElement>) => setAnchorButton(currentTarget);

  const closeHint = () => setAnchorButton(undefined);

  return (
    <div
      aria-owns={anchorButton && "hint"}
      aria-haspopup="true"
      onMouseEnter={(evt) => (disabled ? openHint(evt) : undefined)}
      onMouseLeave={closeHint}
    >
      <Container
        type="button"
        menuModalIsOpen={menuModalIsOpen}
        fromCard={fromCard}
        onClick={disabled ? undefined : onClick}
        disabled={disabled}
      >
        {menuModalIsOpen ? <Cross /> : <ThreeDots />}
      </Container>

      <PopoverHover
        id="hint"
        isOpen={!!anchorButton && disabled && "hintText" in props && props.hintText !== ""}
        element={anchorButton}
        onClose={closeHint}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: 12, horizontal: -12 }}
      >
        {"hintText" in props ? props.hintText : ""}
      </PopoverHover>
    </div>
  );
};

type PropsType = {
  menuModalIsOpen: boolean;
  fromCard?: boolean;
  onClick: (evt: MouseEvent<HTMLButtonElement>) => void;
} & (
  | {
      disabled?: false;
    }
  | {
      disabled: true;
      hintText: string;
    }
);
