import { TriangleDown, TriangleUp } from "@digitalatom/ui";
import { useAtom } from "jotai";
import React, { Dispatch, FC, SetStateAction } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { menuIsExpandState } from "../../../atoms";
import { SectionsType, SectionType } from "../../../constants/sections";
import { Hint } from "../Hint/Hint";
import { useHint } from "../Hint/useHint";
import { Button, Icon, SubSectionsWrapper, Text, TextAndArrow } from "./SectionButtons.styles";
import { SubSectionsButton } from "./SubSectionsButton";

export const FolderButton: FC<PropsType> = (props) => {
  const { section, subSections, openSubmenu, setOpenSubMenu } = props;

  const { name, rout, text, icon, not } = section;

  const popoverHoverMethods = useHint("folderButton", text);
  const { argsForHint } = popoverHoverMethods;

  const [menuIsExpand, setMenuIsExpand] = useAtom(menuIsExpandState);

  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  const handleOpenSubMenu = () =>
    openSubmenu === name ? setOpenSubMenu(undefined) : setOpenSubMenu(name);

  const active = !openSubmenu
    ? pathname.includes(name) && (!not || !pathname.includes(not))
    : openSubmenu === name;

  const canOpenSubMenu =
    !pathname.includes(name) || (pathname.includes(name) && (!not || pathname.includes(not)));

  return (
    <>
      <Button
        isExpand={menuIsExpand}
        active={active}
        onClick={() => {
          canOpenSubMenu && navigate(rout);
          !menuIsExpand ? setMenuIsExpand(true) : undefined;
          handleOpenSubMenu();
        }}
        {...argsForHint}
      >
        <Icon active={active}>{icon}</Icon>

        {menuIsExpand && (
          <TextAndArrow active={active}>
            <Text active={active} onClick={handleOpenSubMenu}>
              {text}
            </Text>

            {openSubmenu === name ? <TriangleUp /> : <TriangleDown />}
          </TextAndArrow>
        )}
      </Button>

      <SubSectionsWrapper isOpen={openSubmenu === name}>
        {subSections.map((subSection, i) =>
          subSection ? <SubSectionsButton key={i} subSection={subSection} /> : null
        )}
      </SubSectionsWrapper>

      <Hint type="folderButton" popoverHoverMethods={popoverHoverMethods} />
    </>
  );
};

type PropsType = {
  section: SectionType;
  subSections: SectionsType;
  openSubmenu: string | undefined;
  setOpenSubMenu: Dispatch<SetStateAction<string | undefined>>;
};
