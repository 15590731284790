import React, { FC } from "react";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { Error } from "../../../styles";
import { OptionType } from "../../../utils/getOptions";
import { Radio } from "../../Icons";
import * as elements from "./RadioInput.styles";

export const RadioInput: FC<RadioInputPropsType> = (props) => {
  const { name, title, options, isColumn = false } = props;
  const { Container, RadioTitle, Text, Title, Wrapper } = elements;
  const { RadioWrapper, RadioDescription } = elements;

  const controller = useController({ name });
  const { fieldState } = controller;
  const { error: errorObj } = fieldState;
  const error = errorObj?.message;

  const context = useFormContext();
  const setValue = (data: string) => context.setValue(name, data);

  const value = useWatch({ name }) as string;

  return (
    <Wrapper>
      {title ? <Title>{title}</Title> : null}

      <Container isColumn={isColumn}>
        {options.map(({ id, name, description, disabled }) => (
          <RadioWrapper key={id}>
            <RadioTitle onClick={() => !disabled && setValue(String(id))}>
              <Radio checked={String(value) === String(id)} disabled={disabled} />
              <Text disabled={disabled}>{name}</Text>
            </RadioTitle>
            <RadioDescription>{description}</RadioDescription>
          </RadioWrapper>
        ))}
      </Container>
      {error && <Error>{error}</Error>}
    </Wrapper>
  );
};

export type RadioInputPropsType = {
  name: string;
  title?: string;
  options: RadioOptionsType;
  isColumn?: boolean;
};

export type RadioOptionsType = (OptionType & {
  disabled?: boolean;
})[];
