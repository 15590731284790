import { CrossOnCircle } from "@digitalatom/ui";
import React, { FC } from "react";
import { Cross, CrossInCircle } from "../../Icons";
import { Container } from "./CloseButton.styles";

export const CloseButton: FC<PropsType> = (props) => {
  const { onClick, type = "cross", inverted = false } = props;

  return (
    <Container onClick={onClick} inverted={inverted}>
      {type === "crossInCircle" ? (
        <CrossInCircle />
      ) : type === "crossOnCircle" ? (
        <CrossOnCircle />
      ) : (
        <Cross />
      )}
    </Container>
  );
};

type PropsType = {
  onClick: () => void;
  type?: "cross" | "crossInCircle" | "crossOnCircle";
  inverted?: boolean;
};
