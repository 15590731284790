import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { feedbackNotificationsSettingsApi, GetFeedbackNotificationsSettingsType } from "../../api";
import { fetchItems } from "../../utils/fetchData";

export const feedbackNotificationsSettingsModalDataState = atom<
  GetFeedbackNotificationsSettingsType | undefined
>(undefined);

// ------------------------------ ДАННЫЕ

export const feedbackNotificationsSettingsState = atomWithQuery(() => ({
  queryKey: ["feedbackNotificationsSettingsState"],
  queryFn: () => fetchItems(() => feedbackNotificationsSettingsApi().getAll()),
}));
