import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import { getTime } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { LOGOUT_HREF } from "../constants/routes";
import { getBackendURI } from "./getBackendURI";
import { now as nowDate } from "./workingWithDates";

export const useCachingKeycloak = () => {
  const { keycloak } = useKeycloak();

  const [hasToken, setHasToken] = useState(false);
  const [initialized, setInitialized] = useState<false | "init" | "refresh">(false);
  const loginRedirectTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const code = new URLSearchParams(location.search).get("code");

  const cacheToken = getToken("token");
  const cacheKcToken = getToken("kc_token");

  const timeout =
    process.env.TIMEOUT && typeof process.env.TIMEOUT === "number" ? process.env.TIMEOUT : 1000;

  const nowInSeconds = getTime(nowDate) / timeout;

  // ------------------------------ ПЕРВИЧНАЯ ИНИЦИАЛИЗАЦИЯ КЭША

  useEffect(() => {
    if (cacheToken && cacheKcToken) {
      const cacheCode = localStorage.getItem("code");
      if (code && cacheCode !== code) {
        removeKeys();
        localStorage.setItem("code", code);
        setInitialized("init");
      } else if (cacheToken.expiresIn > nowInSeconds && cacheKcToken.expiresIn > nowInSeconds) {
        setHasToken(true);
      } else if (
        (cacheToken.expiresIn < nowInSeconds && cacheKcToken.refreshExpiresIn > nowInSeconds) ||
        (cacheKcToken.expiresIn < nowInSeconds && cacheKcToken.refreshExpiresIn > nowInSeconds)
      ) {
        setInitialized("refresh");
      } else {
        removeKeys();
        setInitialized("init");
      }
    } else {
      setInitialized("init");
    }
    // eslint-disable-next-line
  }, []);

  // ------------------------------ ОБРАБОТКА KEYCLOAK ТОКЕНА

  useEffect(() => {
    if (keycloak.authenticated && keycloak.token) {
      loginRedirectTimeoutRef.current && clearTimeout(loginRedirectTimeoutRef.current);

      if (initialized) {
        const keycloakToken: CachingTokenType = {
          token: keycloak.token ?? "",
          refresh: keycloak.refreshToken ?? "",
          expiresIn: keycloak.idTokenParsed?.exp ?? 0,
          refreshExpiresIn: keycloak.refreshTokenParsed?.exp ?? 0,
        };
        localStorage.setItem("kc_token", JSON.stringify(keycloakToken));
        loadToken();
      } else if (cacheToken && cacheKcToken) {
        setTimeout(() => {
          //todoDa: в идеале тут нужно сделать что-то на подобии
          // keycloak.idTokenParsed?.exp = cacheKcToken.expiresIn
          // но, ни одна из установок времени таким способом не работает, нужно искать другие пути
          if (keycloak.onTokenExpired) keycloak.onTokenExpired();
        }, (cacheKcToken.expiresIn - nowInSeconds) * timeout);
      }
    } else if (!loginRedirectTimeoutRef.current) {
      loginRedirectTimeoutRef.current = setTimeout(redirectToLogin, 2500);
    }
  }, [keycloak.authenticated, keycloak.token, initialized]);

  // ------------------------------ ОБНОВЛЕНИЕ ТОКЕНА

  keycloak.onTokenExpired = () => {
    const cacheKc = getToken("kc_token");

    if (cacheKc && cacheKc.refreshExpiresIn > nowInSeconds) {
      keycloak.refreshToken = cacheKc.refresh;
      keycloak
        .updateToken(-1)
        .then(() => {
          setInitialized("refresh");
        })
        .catch(() => {
          removeKeys();
          redirectToLogin();
        });
    } else {
      removeKeys();
      redirectToLogin();
    }
  };

  // ------------------------------ ОБРАБОТКА LIFERAY ТОКЕНА

  const loadToken = () => {
    if (keycloak.authenticated) {
      const clientId = "id-10dbf798-8098-7fd3-214e-2724a18e6e9";
      const clientSecret = "secret-412230eb-179a-4c96-2a59-431789958de";
      const redirectURI = `${window.location.origin}/index.html`;
      const cacheToken = getToken("token");

      if (initialized === "refresh") {
        if (!cacheToken || (cacheToken && cacheToken?.expiresIn < nowInSeconds)) {
          setInitialized(false);
          removeKeys();
          redirectToLogin();
          return;
        }
      } else if (initialized === "init" && code) {
        localStorage.setItem("code", code);
      }

      const body =
        initialized === "init"
          ? `client_id=${clientId}&client_secret=${clientSecret}&grant_type=authorization_code&code=${code}&redirect_uri=${redirectURI}`
          : `client_id=${clientId}&client_secret=${clientSecret}&grant_type=refresh_token&refresh_token=${
              cacheToken?.refresh ?? ""
            }`;

      axios(
        process.env.REACT_APP_MR_URL
          ? "http://localhost:8080/o/oauth2/token"
          : `https://${getBackendURI()}/o/oauth2/token`,
        {
          method: "POST",
          data: body,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
        .then(({ data }) => {
          const { access_token, expires_in, refresh_token } = data;

          const liferayToken: CachingTokenType = {
            token: access_token,
            refresh: refresh_token,
            expiresIn: nowInSeconds + expires_in,
            refreshExpiresIn: 0,
          };

          localStorage.setItem("token", JSON.stringify(liferayToken));

          setHasToken(true);
        })
        .catch((evt) => {
          console.error(evt);
          redirectToLogin();
        });
      setInitialized(false);
    }
  };

  return { authenticated: keycloak.authenticated && hasToken };
};

const removeKeys = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("kc_token");
};

export const getToken = (name: string) => {
  const token = localStorage.getItem(name);

  if (token && !token.includes("expiresIn")) removeKeys();
  else if (token) return JSON.parse(token) as CachingTokenType;

  return null;
};

export const redirectToLogin = () => (window.location.href = LOGOUT_HREF);

type CachingTokenType = {
  token: string;
  refresh: string;
  expiresIn: number;
  refreshExpiresIn: number;
};
