import { CheckMark } from "@digitalatom/ui";
import React, { FC } from "react";
import * as elements from "./DocumentsInput.styles";

export const InputSuccess: FC = () => {
  const { Container, Title, Description, Text, LoadBar } = elements;

  return (
    <Container status="success" as="div">
      <LoadBar status="success">
        <CheckMark />
      </LoadBar>

      <Text>
        <Title status="success">Файлы загружены</Title>
        <Description status="success">Обработка завершается...</Description>
      </Text>
    </Container>
  );
};
