import { Pencil } from "@digitalatom/ui";
import React, { FC, MouseEvent } from "react";
import { Container } from "./EditButton.styles";

export const EditButton: FC<PropsType> = (props) => {
  const { onClick } = props;

  return (
    <Container type="button" onClick={onClick}>
      <Pencil />
    </Container>
  );
};

type PropsType = {
  onClick: (arg: MouseEvent<HTMLButtonElement>) => void;
};
