import { useAtomValue } from "jotai/utils";
import { useMemo } from "react";
import { ProcessTypeType } from "../api";
import * as atoms from "../atoms";
import { patientOutcomesState, workingConditionsClassesState } from "../atoms";
import { getFullName } from "./workingWithNames";

// ------------------------------ Здесь пишем только получаемые опции.
// ------------------------------ Для прописываемых на стороне фронта у нас есть constants/options.ts

// ------------------------------ ОРГАНИЗАЦИИ

export const useOrganizationOptions = () => {
  const organizations = useAtomValue(atoms.organizationsState);

  return organizations.state === "hasData"
    ? organizations.data.map(({ id, name }) => ({ id, name }))
    : [];
};

// ------------------------------ ОСНОВНЫЕ ПОДРАЗДЕЛЕНИЯ

export const useMainDepartmentOptions = () => {
  const mainDepartments = useAtomValue(atoms.mainDepartmentsState);

  return mainDepartments.state === "hasData" && mainDepartments.data
    ? mainDepartments.data.map(({ id, name }) => ({ id, name }))
    : [];
};

// ------------------------------ ПОДРАЗДЕЛЕНИЯ

export const useDepartmentOptions = () => {
  const departments = useAtomValue(atoms.departmentsState);

  return departments.state === "hasData" && departments.data
    ? departments.data.map(({ id, name }) => ({ id, name }))
    : [];
};

// ------------------------------ ДОЛЖНОСТИ

// export const usePositionOptions = () => {
//   const positions = useAtomValue(atoms.positionsState);

//   return positions.state === "hasData" ? positions.data.map(({ id, name }) => ({ id, name })) : [];
// };

// ------------------------------ СОТРУДНИКИ

// export const useEmployeeOptions = () => {
//   const employees = useAtomValue(atoms.employeesState);

//   return employees.state === "hasData" && employees.data
//     ? employees.data.map((employee) => ({
//         id: employee.id,
//         name: getFullName({ nameParts: employee }),
//       }))
//     : [];
// };

// ------------------------------ САНАТОРИИ

export const useSanatoriumOptions = () => {
  const sanatoriums = useAtomValue(atoms.allSanatoriumsState);

  return sanatoriums.map(({ id, name }) => ({ id, name }));
};

// ------------------------------ СТРАНЫ

export const useCountryOptions = (id?: string) => {
  const countries = useAtomValue(atoms.countriesState);

  return countries
    .filter((country) => !id || country.id === id)
    .map(({ id, name }) => ({ id, name }));
};

// ------------------------------ РЕГИОНЫ

export const useRegionOptions = (countryId?: string) => {
  const regions = useAtomValue(atoms.regionsAllState);

  return regions
    .filter((region) => !countryId || region.country.id === countryId)
    .map(({ id, name }) => ({ id, name }));
};

// ------------------------------ ПРОФИЛИ ЛЕЧЕНИЯ

export const useTreatmentProfileOptions = () => {
  const treatmentProfiles = useAtomValue(atoms.treatmentProfilesAllState);

  return treatmentProfiles.map(({ id, name, description }) => ({ id, name, description }));
};

// ------------------------------ МЕТОДЫ ЛЕЧЕНИЯ

export const useTreatmentMethodOptions = () => {
  const treatmentMethods = useAtomValue(atoms.treatmentMethodsAllState);

  return treatmentMethods.map(({ id, name, description }) => ({ id, name, description }));
};

// ------------------------------ УСЛУГИ САНАТОРИЕВ

export const useSanatoriumServiceOptions = () => {
  const sanatoriumServices = useAtomValue(atoms.sanatoriumServicesAllState);

  return sanatoriumServices.map(({ id, name, description }) => ({ id, name, description }));
};

// ------------------------------ КЛАССЫ УСЛОВИЙ ТРУДА

export const useWorkingConditionsClassOptions = () => {
  const workingConditionsClasses = useAtomValue(workingConditionsClassesState);

  return workingConditionsClasses
    .sort((a, b) => Number(a.code.split(".")[0]) - Number(b.code.split(".")[0]))
    .map(({ id, name, code }) => ({ id, name: `${code} ${name}` }));
};

// ------------------------------ ФАКТОРЫ ПРОИЗВОДСТВЕННОЙ СРЕДЫ

export const useOccupationalFactorOptions = () => {
  const occupationalFactors = useAtomValue(atoms.occupationalFactorsState);

  return occupationalFactors.map(({ id, name }) => ({ id, name }));
};

// ------------------------------ ТИПОВЫЕ МЕДИЦИНСКИЕ ПРОГРАММЫ

export const useTypicalMedicalProgramOptions = () => {
  const typicalMedicalPrograms = useAtomValue(atoms.typicalMedicalProgramsAllState);

  return typicalMedicalPrograms
    .sort((a, b) => Number(a.shortName) - Number(b.shortName))
    .map(({ id, name, shortName }) => ({
      id,
      name: `РОМ ${shortName}. ${name}`,
    }));
};

// ------------------------------ АСПЕКТЫ РАБОТЫ СКО

// export const useSanatoriumAspectOptions = () => {
//   const sanatoriumAspects = useAtomValue(sanatoriumAspectsState);

//   return sanatoriumAspects.map(({ id, name }) => ({ id, name }));
// };

// ------------------------------ ГРУППЫ АСПЕКТОВ

export const useAspectGroupOptions = () => {
  const data = useAtomValue(atoms.aspectGroupsState);
  const aspectGroups = data.state === "hasData" ? data.data : [];

  return aspectGroups.map(({ id, name }) => ({ id, name }));
};

// ------------------------------ СОВЕРШЕНСТВОВАНИЕ УСЛУГ И РАБОТЫ СКО

// export const useImproveServiceOptions = () => {
//   const improveServices = useAtomValue(improveServicesState);

//   return improveServices.map(({ id, name }) => ({ id, name }));
// };

// ------------------------------ РЕЗУЛЬТАТИВНОСТЬ ЛЕЧЕНИЯ

export const usePatientOutcomeOptions = () => {
  const patientOutcomes = useAtomValue(patientOutcomesState);

  return patientOutcomes.map(({ id, name }) => ({ id, name }));
};

// ------------------------------ СТАТУСЫ

export const useStatusOptions = (type?: ProcessTypeType) => {
  const data = useAtomValue(atoms.statusesAllState);
  const statuses = data.state === "hasData" ? data.data : [];

  return useMemo(
    () =>
      statuses
        .filter((status) => !type || status.type === type)
        .map(({ id, name }) => ({ id, name })),
    [data]
  );
};

// ------------------------------ ТИПЫ ДОКУМЕНТОВ

export const useDocumentTypeOptions = () => {
  const documentTypes = useAtomValue(atoms.documentTypesAllState);

  return documentTypes.map(({ id, name }) => ({ id, name }));
};

// ------------------------------ ТИПЫ ДОКУМЕНТОВ

export const useWhyNotOptions = () => {
  const whyNot = useAtomValue(atoms.whyNotAllState);

  return whyNot.map(({ id, name }) => ({ id, name }));
};

// ------------------------------ СОТРУДНИКИ ОРГАНИЗАЦИИ

export const useEmployeesOptions = ({ onlyVip }: { onlyVip?: boolean }) => {
  const employees = useAtomValue(atoms.employeesForSelectAtom);
  const employeesWithVip = useAtomValue(atoms.employeesWithVipAtom);

  return employees
    .filter(
      (employee) =>
        !onlyVip ||
        !!employeesWithVip.find(
          (vipPersonnelNumber) => vipPersonnelNumber === employee.personnelNumber
        )
    )
    .map((employee) => ({
      id: employee.personnelNumber,
      name: getFullName({ nameParts: employee }),
      description: employee.personnelNumber,
    }));
};

// ------------------------------ ТИПЫ

export type OptionType = {
  id: string | number;
  name: string;
  description?: string;
};

export type OptionsType = OptionType[];
