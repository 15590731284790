import { BigWarning } from "@digitalatom/ui";
import React, { FC } from "react";
import { Columns } from "../../styles";
import { UniversalTextButton } from "../Buttons";
import { ConfirmModalPropsType } from "./ConfirmModal";
import { Text } from "./ConfirmModal.styles";

export const Warning: FC<ConfirmModalPropsType> = (props) => {
  const { onClose, warningText } = props;

  return (
    <>
      <BigWarning />

      <Text>{warningText}</Text>

      <Columns columns={1}>
        <UniversalTextButton text="Понятно" type="secondary" onClick={onClose} />
      </Columns>
    </>
  );
};
