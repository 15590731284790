import { SearchInput } from "@digitalatom/ui";
import { useAtom } from "jotai";
import React, { FC, ReactNode, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { searchQueryState, visibleSearchState } from "../../atoms";
import { Container } from "./Header.styles";

export const Header: FC<PropsType> = (props) => {
  const { navigation, pagesSwitcher } = props;

  const [_, setSearchParams] = useSearchParams();

  const [visibleSearch, setVisibleSearch] = useAtom(visibleSearchState);
  const [query, setQuery] = useAtom(searchQueryState);

  useEffect(() => setVisibleSearch(false), []);

  return (
    <Container>
      {navigation}
      {visibleSearch && (
        <SearchInput query={query} setQuery={setQuery} setSearchParams={setSearchParams} />
      )}
      {!!pagesSwitcher && pagesSwitcher}
    </Container>
  );
};

type PropsType = {
  navigation: ReactNode;
  pagesSwitcher?: JSX.Element | null;
};
