import { Loupe } from "@digitalatom/ui";
import React, { FC } from "react";
import { Container } from "./SearchButton.styles";

export const SearchButton: FC<PropsType> = (props) => {
  const { onClick, visibleSearch } = props;

  return (
    <Container onClick={onClick} visibleSearch={visibleSearch}>
      <Loupe />
    </Container>
  );
};

type PropsType = {
  onClick: () => void;
  visibleSearch: boolean;
};
