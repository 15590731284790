import { DateRange } from "@mui/lab";
import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable, useAtomValue } from "jotai/utils";
import { applicationsApi, ApplicationType, EntitiesType, fetchPlans, SortType } from "../../api";
import { statusIds } from "../../constants/statusIds";
import { fetchAll, fetchItems } from "../../utils/fetchData";
import { getQuerySearchConditions } from "../../utils/getQuerySearchConditions";
import { getPaginationQueryKeys, usePaginationQueryKeys } from "../../utils/useQueryKeys";
import { formatDate, today } from "../../utils/workingWithDates";
import {
  applicationsTypeState,
  ApplicationsTypeType,
  currentDateInterval,
  currentUserAtom,
  registryPreviewState,
  sortTypeState,
  statusesAllState,
  UserType,
} from "../index";

export const applicationsAbsoluteInitialValues: FiltersType = {
  status: "",
  sanatorium: "",
  since: null,
  until: null,
};

export const applicationsFiltersState = atom<FiltersType>(applicationsAbsoluteInitialValues);

export type FiltersType = {
  status: string;
  sanatorium: "";
  since: Date | null;
  until: Date | null;
};

// ------------------------------ ТИП МОЕЙ ЗАЯВКИ

export type MyApplicationsTypeType = "myOwn" | "others";

export const myApplicationsTypeState = atom<MyApplicationsTypeType>("myOwn");

// ------------------------------ ЗАЯВКИ

const applicationsAtom = atomWithQuery((get) => {
  const user = get(currentUserAtom);

  const applicationsType = get(applicationsTypeState);
  const myApplicationsType = get(myApplicationsTypeState);

  const { startIndex, size, query } = getPaginationQueryKeys(get);
  const dateInterval = get(currentDateInterval);
  const filters = get(applicationsFiltersState);
  const sort = get(sortTypeState);

  const args = {
    user,
    applicationsType,
    myApplicationsType,
    startIndex,
    size,
    query,
    filters,
    sort,
    dateInterval,
  };

  return applicationsQuery(args);
});

export const applicationsState = loadable(applicationsAtom);

const applicationsQuery = (props: PropsType) => {
  const { keys, args } = getArgs(props);

  return {
    queryKey: [...keys, "applicationsState"],
    queryFn: () => fetchAll(() => applicationsApi().getAll(args)),
    keepPreviousData: true,
  };
};

// ------------------------------ ВСЕ ЗАЯВКИ

export const canDownloadApplicationsAtom = atom<boolean>(false);

export const allApplicationsQuery = (props: PropsType & { canDownloadApplications: boolean }) => {
  const { canDownloadApplications } = props;

  const { keys, args } = getArgs(props);

  return {
    queryKey: [...keys, "allApplicationsState"],
    queryFn: () => fetchItems(() => applicationsApi().getAll(args)),
    keepPreviousData: true,
    enabled: canDownloadApplications,
  };
};

// ------------------------------ ДАННЫЕ ДЛЯ ПРЕДВАРИТЕЛЬНОГО ПРОСМОТРА РЕЕСТРА

const applicationsRegistryPreviewAtom = atomWithQuery((get) => {
  const statuses = get(statusesAllState);
  const isActive = get(registryPreviewState);
  const sort = get(sortTypeState);

  const args = {
    filter:
      isActive === "application"
        ? [
            {
              property: "status",
              operator: "=",
              value: statusIds.osr_approved_completeness,
            },
          ]
        : [
            {
              group: "AND",
              conditions: [
                {
                  group: "OR",
                  conditions: [
                    {
                      property: "status",
                      operator: "=",
                      value: statusIds.required_endOfTheRest,
                    },
                    {
                      property: "status",
                      operator: "=",
                      value: statusIds.closed_application,
                    },
                  ],
                },
                {
                  property: "voucherRegistry.id",
                  operator: "=",
                  value: "",
                },
              ],
            },
          ],
    fetchPlan: fetchPlans.resort_Application.get,
    sort,
  };

  return {
    queryKey: [statuses, isActive, "applicationsRegistryPreviewState"],
    queryFn: () => (isActive ? fetchItems(() => applicationsApi().getAll(args)) : []),
    keepPreviousData: true,
  };
});

export const applicationsRegistryPreviewState = loadable(applicationsRegistryPreviewAtom);

export const applicationsRegistryPreviewData = atom<EntitiesType<ApplicationType> | undefined>(
  undefined
);

// ------------------------------ ВЫЧИСЛЕНИЕ АРГУМЕНТОВ

export const useApplicationsKeys = () => {
  const user = useAtomValue(currentUserAtom);

  const applicationsType = useAtomValue(applicationsTypeState);
  const myApplicationsType = useAtomValue(myApplicationsTypeState);

  const { startIndex, size, query } = usePaginationQueryKeys();

  const dateInterval = useAtomValue(currentDateInterval);
  const filters = useAtomValue(applicationsFiltersState);
  const sort = useAtomValue(sortTypeState);

  const args = {
    user,
    applicationsType,
    myApplicationsType,
    startIndex,
    size,
    query,
    filters,
    sort,
    dateInterval,
  };

  return getArgs(args).keys;
};

const getArgs = (props: PropsType) => {
  const { user, applicationsType, startIndex, size } = props;
  const { query, filters, sort, dateInterval, myApplicationsType } = props;

  const employeeId = user ? user.res.id : undefined;
  const status = filters.status || undefined;
  const sanatorium = filters.sanatorium || undefined;
  const since = filters.since ? formatDate({ date: filters.since, type: "forBackend" }) : undefined;
  const until = filters.until ? formatDate({ date: filters.until, type: "forBackend" }) : undefined;

  const startDate = formatDate({ date: dateInterval[0] ?? today, type: "forBackend" });
  const endDate = formatDate({ date: dateInterval[1] ?? today, type: "forBackend" });

  const isMyApplications = employeeId && applicationsType === "my";

  const needOnlyMyOwn = myApplicationsType === "myOwn";

  const needPeriod =
    applicationsType !== "my" ||
    (applicationsType === "my" &&
      (user?.roleModel.department || user?.roleModel.osr || user?.roleModel.osrCa));

  const filter = [
    ...(isMyApplications
      ? [
          needOnlyMyOwn
            ? { property: "employee.id", operator: "=", value: employeeId }
            : {
                group: "AND",
                conditions: [
                  { property: "applicant.id", operator: "=", value: employeeId },
                  { property: "employee.id", operator: "<>", value: employeeId },
                ],
              },
        ]
      : []),

    ...(status ? [{ property: "status", operator: "=", value: status }] : []),
    ...(sanatorium ? [{ property: "room.sanatorium", operator: "=", value: sanatorium }] : []),

    ...(since && needPeriod ? [{ property: "until", operator: ">=", value: since }] : []),
    ...(until && needPeriod ? [{ property: "until", operator: "<=", value: until }] : []),

    ...(startDate
      ? [
          {
            property: "createdDate",
            operator: ">=",
            value: `${formatDate({ date: startDate, type: "bigDate" })}`,
          },
        ]
      : []),
    ...(endDate
      ? [
          {
            property: "createdDate",
            operator: "<=",
            value: `${formatDate({ date: endDate, type: "bigDate" })}`,
          },
        ]
      : []),

    ...(query
      ? [
          getQuerySearchConditions(query, [
            "employee.firstName",
            "employee.middleName",
            "employee.lastName",
          ]),
        ]
      : []),
  ];

  return {
    keys: [
      startIndex,
      size,
      query,
      employeeId,
      status,
      sanatorium,
      startDate,
      endDate,
      applicationsType,
      myApplicationsType,
    ],
    args: { startIndex, size, startDate, endDate, filter, sort },
  };
};

type PropsType = {
  user?: UserType;
  applicationsType: ApplicationsTypeType | "";
  myApplicationsType: MyApplicationsTypeType;
  startIndex?: number;
  size?: number;
  query?: string;
  filters: FiltersType;
  sort: SortType;
  dateInterval: DateRange<Date>;
};
