import { Trash } from "@digitalatom/ui";
import React, { FC } from "react";
import { Container } from "./TrashButton.styles";

export const TrashButton: FC<PropsType> = (props) => {
  const { onClick } = props;

  return (
    <Container type="button" onClick={onClick}>
      <Trash />
    </Container>
  );
};

type PropsType = {
  onClick: () => void;
};
