import { Sieve } from "@digitalatom/ui";
import { useAtom } from "jotai";
import { useUpdateAtom } from "jotai/utils";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { filtersFormState, visibleSearchState } from "../../../atoms";
import { SearchButton, UniversalTextButton } from "../../Buttons";
import { DateSwitcher } from "../../DateSwitcher";
import { HeaderNavigationPropsType } from "./HeaderNavigation";
import { RightButtons } from "./HeaderNavigation.styles";

export const HeaderNavigationRightButtons = <VOBJ,>(props: HeaderNavigationPropsType<VOBJ>) => {
  const { needSearchButton, needFilterButton, hasActiveFilters = false, needDateSwitcher } = props;

  const [_, setSearchParams] = useSearchParams();

  const setFiltersFormIsOpen = useUpdateAtom(filtersFormState);

  // ------------------------------ < ОТОБРАЖЕНИЕ/СКРЫТИЕ СТРОКИ ПОИСКА

  const [visibleSearch, setVisibleSearch] = useAtom(visibleSearchState);

  const openSearch = () => {
    if (!needSearchButton) return;

    setVisibleSearch(!visibleSearch);

    setSearchParams({ page: String(1) });
  };

  // ------------------------------ ОТОБРАЖЕНИЕ/СКРЫТИЕ СТРОКИ ПОИСКА >

  return (
    <RightButtons>
      {needSearchButton && <SearchButton onClick={openSearch} visibleSearch={visibleSearch} />}

      {!!needFilterButton && (
        <UniversalTextButton
          text="Фильтры"
          type="text"
          needPoint={hasActiveFilters}
          startIcon={<Sieve />}
          onClick={() => setFiltersFormIsOpen(true)}
        />
      )}

      {!!needDateSwitcher && <DateSwitcher {...needDateSwitcher} />}
    </RightButtons>
  );
};
