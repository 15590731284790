import { NineDots } from "@digitalatom/ui";
import { useAtomValue } from "jotai/utils";
import React, { FC } from "react";
import { menuIsExpandState } from "../../../atoms";
import { useHint } from "../Hint/useHint";
import { Hint } from "../components";
import { Container, Icon, Text } from "./WidgetsButton.styles";

export const WidgetsButton: FC<PropsType> = (props) => {
  const { baseUrl } = props;

  const popoverHoverMethods = useHint("menu", "Меню");
  const { argsForHint } = popoverHoverMethods;

  const menuIsExpand = useAtomValue(menuIsExpandState);

  return (
    <Container
      isExpand={menuIsExpand}
      onClick={() => {
        window.location.href = `http://${baseUrl}`;
        argsForHint.onMouseLeave();
      }}
      {...argsForHint}
    >
      <Icon>
        <NineDots />
      </Icon>

      {menuIsExpand && <Text>Меню</Text>}

      <Hint type="menu" popoverHoverMethods={popoverHoverMethods} />
    </Container>
  );
};

type PropsType = {
  baseUrl?: string;
};
