import { BigCrossInCircle } from "@digitalatom/ui";
import React, { FC } from "react";
import { Columns } from "../../styles";
import { UniversalTextButton } from "../Buttons";
import { ConfirmModalPropsType } from "./ConfirmModal";
import { Description, Text } from "./ConfirmModal.styles";

export const Error: FC<ConfirmModalPropsType & { error?: string }> = (props) => {
  const { question, onClose, error } = props;

  return (
    <>
      <BigCrossInCircle />

      <Text>
        {question && "onDelete" in question.coloredButton ? "При удалении в" : "В"}
        озникла следующая ошибка:
      </Text>
      <Description>{error}</Description>

      <Columns columns={1}>
        <UniversalTextButton text="Понятно" type="secondary" onClick={onClose} />
      </Columns>
    </>
  );
};
