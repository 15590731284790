import { Order } from "@digitalatom/ui";
import { MenuItem } from "@mui/material";
import React, { FC } from "react";
import { OptionsType } from "../../utils/getOptions";
import * as elements from "./Select.styles";

export const Select: FC<PropsType> = (props) => {
  const { options, onChange, value } = props;

  const { Item, Container, MenuItemShowWrapper, InputProps, MenuItemWrapper, MenuProps } = elements;

  const renderValue = (value: string) => (
    <MenuItemShowWrapper>{options.find(({ id }) => id === value)?.name ?? ""}</MenuItemShowWrapper>
  );

  return (
    <Container>
      <Order />
      <Item
        value={value}
        MenuProps={MenuProps()}
        renderValue={(value) => renderValue(value as string)}
        renderSuffix={() => null}
        inputProps={InputProps}
        onChange={(evt) => onChange((evt.target.value as string) ?? "")}
      >
        {options.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            <MenuItemWrapper>{name}</MenuItemWrapper>
          </MenuItem>
        ))}
      </Item>
    </Container>
  );
};

type PropsType = {
  options: OptionsType;
  onChange: (arg: string) => void;
  value?: string;
};
