import { BigCheck } from "@digitalatom/ui";
import React, { FC } from "react";
import { Columns } from "../../styles";
import { UniversalTextButton } from "../Buttons";
import { ConfirmModalPropsType, StatusType } from "./ConfirmModal";
import { Description, Text } from "./ConfirmModal.styles";

export const Success: FC<ConfirmModalPropsType & { status: StatusType }> = (props) => {
  const { success, onClose, status } = props;

  return success ? (
    <>
      <BigCheck />

      <Text>{success.text}</Text>
      <Description>{success.description}</Description>

      {status === "success" ? (
        <Columns columns={success.outlinedButton && success.coloredButton ? 2 : 1}>
          {success.outlinedButton && success.coloredButton ? (
            <UniversalTextButton
              text={success.outlinedButton?.text}
              type="outlined"
              onClick={() => {
                success.outlinedButton?.onClick();
                onClose();
              }}
            />
          ) : null}

          <UniversalTextButton
            text={success.coloredButton?.text ?? "Хорошо"}
            type="secondary"
            onClick={() => {
              success.coloredButton?.onClick();
              onClose();
            }}
          />
        </Columns>
      ) : null}
    </>
  ) : null;
};
