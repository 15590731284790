/**
 *
 * ------------------------------------------------------------------------------------------
 * **ТАЙМЕР ЗАДЕРЖКИ ДЛЯ ЗАПРОСОВ**
 *
 * @param time - время задержки в миллисекундах (по умолчанию - 1500 мс)
 * @returns промис, который будет разрешён через указанное или стандартное время
 *
 */

export const timerForQueries = async (time?: number) =>
  new Promise((resolve) => setTimeout(resolve, time || 1500));
