import { AxiosResponse } from "axios";
import { hrApi } from "./api";
import { OtherWithIconType, WorkingConditionsClassType } from "./dictionaries";
import { fetchPlans } from "./fetchPlans";
import { organizationsApi } from "./organization";
import { entitiesBase, EntitiesResponseType, EntityUpdateType } from "./request";
import { IdType } from "./types";

// ------------------------------ РАСЧЁТ СТОИМОСТИ ПУТЁВКИ

export const calculatePriceSettingsApi = <CS>() => ({
  ...entitiesBase<CalculationSettingsType, CS, undefined>(hrApi, "resort_CalculatePriceSettings"),
  getAll: async (): Promise<AxiosResponse<EntitiesResponseType<CalculationSettingsType>>> => {
    const organizations = (await organizationsApi.get()).data.items;

    const params = {
      params: { returnCount: true, fetchPlan: fetchPlans.resort_CalculatePriceSettings },
    };

    const res = await hrApi.get("/resort_CalculatePriceSettings", params);

    const items = (res.data as CalculationSettingsType[]).map((datum) => ({
      ...datum,
      organizationName: organizations.find(({ code }) => code === datum.organization)?.name,
    }));

    return { ...res, data: { items, totalCount: Number(res.headers["x-total-count"]) } };
  },
});

// ------------------------------ ТИПЫ - РАСЧЁТ СТОИМОСТИ ПУТЁВКИ

// ----- получаемые данные

export type CalculationSettingsType = IdType & {
  organization: string;
  organizationName?: string;
  normativePrice: number;
  spouseDiscount: number;
};

// ------------------------------ КРАТНОСТЬ ПРЕДОСТАВЛЕНИЯ ПУТЕВКИ

export const frequencySettingsApi = <FS>() =>
  entitiesBase<FrequencySettingsType, FS, undefined>(
    hrApi,
    "resort_VouchersFrequencyCalculationRule"
  );

// ------------------------------ ТИПЫ - КРАТНОСТЬ ПРЕДОСТАВЛЕНИЯ ПУТЕВКИ

// ----- получаемые данные

export type FrequencySettingsType = IdType & {
  experienceFrom: number;
  experienceUpTo?: number;
  frequency: number;
  normalWorkingConditions: boolean;
  factor: OtherWithIconType | null;
  conditionClass: WorkingConditionsClassType | null;
};

// ------------------------------ НАСТРОЙКА УВЕДОМЛЕНИЙ ПО АНКЕТЕ ОБРАТНОЙ СВЯЗИ

export const feedbackNotificationsSettingsApi = () => ({
  getAll: async (): Promise<
    AxiosResponse<EntitiesResponseType<GetFeedbackNotificationsSettingsType>>
  > => {
    const res = await hrApi.get("/resort_RatingNotificationSettings");
    const data = res.data as FeedbackNotificationsSettingsType;

    const items = [
      {
        id: "1",
        i: data[0].id,
        type: "initialNotification" as ConditionTypeType,
        value: data[0].initialNotification,
      },
      {
        id: "2",
        i: data[0].id,
        type: "resendingNotifications" as ConditionTypeType,
        value: data[0].resendingNotifications,
      },
    ];

    return { ...res, data: { items, totalCount: 2 } };
  },

  update: (props: EntityUpdateType<PutFeedbackNotificationsSettingsType>) => {
    const { id, initialNotification, resendingNotifications } = props.data;

    const data = { initialNotification, resendingNotifications };

    return hrApi.put(`/resort_RatingNotificationSettings/${id}`, data);
  },
});

// ------------------------------ ТИПЫ - НАСТРОЙКА УВЕДОМЛЕНИЙ ПО АНКЕТЕ ОБРАТНОЙ СВЯЗИ

export type FeedbackNotificationsSettingsType = (IdType & {
  initialNotification: number;
  resendingNotifications: number;
})[];

// ----- передаваемые значения

export type PutFeedbackNotificationsSettingsType = IdType & {
  initialNotification: number;
  resendingNotifications: number;
};

// ----- получаемые данные

export type GetFeedbackNotificationsSettingsType = IdType & {
  i: string;
  type: ConditionTypeType;
  value: number;
};

export type ConditionTypeType = "initialNotification" | "resendingNotifications";
