import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import { RoleModelType } from "../constants/rights";
import * as routes from "../constants/routes";
import { WarningPage } from "../pages/WarningPage";
import "../styles/index.css";

const pageImport = (address: string) => () => import(`../pages/${address}`);
const dictionaryPageImport = (address: string) => pageImport(`DictionaryPages/${address}`);
const lifecyclePageImport = (address: string) => pageImport(`ApplicationLifecyclePages/${address}`);

const UserProfilePage = lazy(pageImport("UserProfile"));
const SanatoriumListPage = lazy(pageImport("SanatoriumListPage"));
const Rooms = lazy(pageImport("SanatoriumListPage/Rooms"));

const SanatoriumDictionaryPage = lazy(dictionaryPageImport("SanatoriumsAndRooms"));
const EmployeeDictionaryPage = lazy(dictionaryPageImport("Employees"));
const CountryDictionaryPage = lazy(dictionaryPageImport("Countries"));
const RegionDictionaryPage = lazy(dictionaryPageImport("Regions"));
const TreatmentProfileDictionaryPage = lazy(dictionaryPageImport("TreatmentProfiles"));
const TreatmentMethodDictionaryPage = lazy(dictionaryPageImport("TreatmentMethods"));
const SanatoriumServiceDictionaryPage = lazy(dictionaryPageImport("SanatoriumServices"));
const WorkingConditionsClassDictionaryPage = lazy(dictionaryPageImport("WorkingConditionsClasses"));
const OccupationalFactorDictionaryPage = lazy(dictionaryPageImport("OccupationalFactors"));
const TypicalMedicalProgramDictionaryPage = lazy(dictionaryPageImport("TypicalMedicalPrograms"));
const SanatoriumAspectDictionaryPage = lazy(dictionaryPageImport("SanatoriumAspects"));
const AspectGroupDictionaryPage = lazy(dictionaryPageImport("AspectGroups"));
const ImproveServiceDictionaryPage = lazy(dictionaryPageImport("ImproveServices"));
const PatientOutcomeDictionaryPage = lazy(dictionaryPageImport("PatientOutcomes"));
const StatusDictionaryPage = lazy(dictionaryPageImport("Statuses"));
const DocumentTypeDictionaryPage = lazy(dictionaryPageImport("DocumentTypes"));
const WhyNotDictionaryPage = lazy(dictionaryPageImport("WhyNot"));
const RehabActivitiesPage = lazy(dictionaryPageImport("RehabActivities"));

const ApplicationsPage = lazy(lifecyclePageImport("Applications/Applications"));
const TasksPage = lazy(lifecyclePageImport("Applications/Tasks"));
const ApplicationPage = lazy(lifecyclePageImport("Applications/Application"));
const ApplicationFormPage = lazy(lifecyclePageImport("Applications/ApplicationForm"));
const ApplicationsFeedbackFormPage = lazy(lifecyclePageImport("Applications/FeedbackForm"));
const ApplicationsFeedbackPage = lazy(lifecyclePageImport("Applications/Feedback"));

const RegistersApplicationPage = lazy(
  lifecyclePageImport("Registries/Application/RegistersApplication")
);
const RegisterApplicationsPage = lazy(
  lifecyclePageImport("Registries/Application/RegisterApplications")
);
const RegistersTravelPackagePage = lazy(
  lifecyclePageImport("Registries/TravelPackage/RegistersTravelPackage")
);
const RegisterTravelPackagesPage = lazy(
  lifecyclePageImport("Registries/TravelPackage/RegisterTravelPackages")
);

const CalculationSettingsPage = lazy(pageImport("AdministrationPages/CalculationSettingsPage"));
const FrequencySettingsPage = lazy(pageImport("AdministrationPages/FrequencySettingsPage"));
const FeedbackNotificationsSettingsPage = lazy(
  pageImport("AdministrationPages/FeedbackNotificationsSettingsPage")
);
const ReportingPage = lazy(pageImport("AdministrationPages/ReportingPage"));
const FeedbackPage = lazy(pageImport("FeedbackPage"));

const DocumentationsPage = lazy(pageImport("DocumentationsPage"));

export const routesAndElements = (roleModel: RoleModelType) => {
  const { sk, ok, osr, operator, module, employee, department } = roleModel;

  return [
    { path: "*", element: <WarningPage code="page_not_found" /> },
    {
      path: "/",
      element: (
        <Navigate
          to={
            operator || module
              ? routes.DICTIONARY_SANATORIUMS
              : ok
              ? routes.APPLICATIONS_INBOX
              : sk
              ? routes.REGISTRIES_APPLICATION
              : routes.SANATORIUMS
          }
        />
      ),
    },

    // ------------------------------ ЛИЧНАЯ КАРТОЧКА РАБОТНИКА ------------------------------

    { path: routes.USER(), element: <UserProfilePage type="personalInfo" /> },
    { path: routes.USER_PERSONAL_INFO(), element: <UserProfilePage type="personalInfo" /> },
    { path: routes.USER_FAMILY_MEMBERS(), element: <UserProfilePage type="family-members" /> },
    { path: routes.USER_DOCUMENTS(), element: <UserProfilePage type="documents" /> },
    { path: routes.USER_FACTORS(), element: <UserProfilePage type="factors" /> },

    // ------------------------------ САНАТОРИИ ------------------------------

    { path: routes.SANATORIUMS, element: <SanatoriumListPage /> },
    { path: routes.SANATORIUM_ROOMS(undefined), element: <Rooms /> },

    // ------------------------------ СПРАВОЧНИК САНАТОРИЕВ ------------------------------

    {
      path: routes.DICTIONARY_SANATORIUMS,
      element: <SanatoriumDictionaryPage content="sanatoriums" type="list" />,
    },
    {
      path: routes.DICTIONARY_SANATORIUM_FORM(undefined),
      element: <SanatoriumDictionaryPage content="sanatoriums" type="form" />,
    },
    {
      path: routes.DICTIONARY_SANATORIUM_PREVIEW(undefined),
      element: <SanatoriumDictionaryPage content="sanatoriums" type="preview" />,
    },
    {
      path: routes.DICTIONARY_SANATORIUM_HISTORY(undefined),
      element: <SanatoriumDictionaryPage content="sanatoriums" type="history" />,
    },
    {
      path: routes.DICTIONARY_SANATORIUM_ROOMS(undefined),
      element: <SanatoriumDictionaryPage content="rooms" type="list" />,
    },
    {
      path: routes.DICTIONARY_SANATORIUM_ROOM_FORM(undefined, undefined),
      element: <SanatoriumDictionaryPage content="rooms" type="form" />,
    },
    {
      path: routes.DICTIONARY_SANATORIUM_ROOM_PREVIEW(undefined, undefined),
      element: <SanatoriumDictionaryPage content="rooms" type="preview" />,
    },
    {
      path: routes.DICTIONARY_SANATORIUM_ROOM_HISTORY(undefined, undefined),
      element: <SanatoriumDictionaryPage content="rooms" type="history" />,
    },

    // ------------------------------ СПРАВОЧНИКИ ------------------------------

    {
      path: routes.DICTIONARY,
      element: (
        <Navigate to={operator ? routes.DICTIONARY_COUNTRIES : routes.DICTIONARY_EMPLOYEES} />
      ),
    },
    { path: routes.DICTIONARY_EMPLOYEES, element: <EmployeeDictionaryPage /> },
    { path: routes.DICTIONARY_COUNTRIES, element: <CountryDictionaryPage /> },
    { path: routes.DICTIONARY_REGIONS, element: <RegionDictionaryPage /> },
    { path: routes.DICTIONARY_TREATMENT_PROFILE, element: <TreatmentProfileDictionaryPage /> },
    { path: routes.DICTIONARY_TREATMENT_METHODS, element: <TreatmentMethodDictionaryPage /> },
    { path: routes.DICTIONARY_SANATORIUM_SERVICES, element: <SanatoriumServiceDictionaryPage /> },
    {
      path: routes.DICTIONARY_WORKING_CONDITIONS,
      element: <WorkingConditionsClassDictionaryPage />,
    },
    { path: routes.DICTIONARY_OCCUPATIONAL_FACTORS, element: <OccupationalFactorDictionaryPage /> },
    {
      path: routes.DICTIONARY_TYPICAL_MEDICAL_PROGRAMS,
      element: <TypicalMedicalProgramDictionaryPage />,
    },
    { path: routes.DICTIONARY_SANATORIUM_ASPECTS, element: <SanatoriumAspectDictionaryPage /> },
    { path: routes.DICTIONARY_ASPECT_GROUPS, element: <AspectGroupDictionaryPage /> },
    { path: routes.DICTIONARY_IMPROVE_SERVICES, element: <ImproveServiceDictionaryPage /> },
    { path: routes.DICTIONARY_PATIENT_OUTCOMES, element: <PatientOutcomeDictionaryPage /> },
    { path: routes.DICTIONARY_STATUSES, element: <StatusDictionaryPage /> },
    { path: routes.DICTIONARY_DOCUMENT_TYPES, element: <DocumentTypeDictionaryPage /> },
    { path: routes.DICTIONARY_WHY_NOT, element: <WhyNotDictionaryPage /> },
    { path: routes.DICTIONARY_REHAB_ACTIVITY, element: <RehabActivitiesPage /> },

    // ------------------------------ ЗАЯВКИ ------------------------------

    {
      path: routes.APPLICATIONS,
      element: (
        <Navigate
          to={
            module
              ? routes.DICTIONARY_SANATORIUMS
              : sk
              ? routes.REGISTRIES_APPLICATION
              : employee || department
              ? routes.APPLICATIONS_MY
              : routes.APPLICATIONS_INBOX
          }
        />
      ),
    },

    { path: routes.APPLICATIONS_ALL, element: <ApplicationsPage /> },
    { path: routes.APPLICATIONS_MY, element: <ApplicationsPage /> },

    { path: routes.APPLICATIONS_INBOX, element: <TasksPage /> },
    { path: routes.APPLICATIONS_IN_PROCESS, element: <TasksPage /> },
    { path: routes.APPLICATIONS_PROCESSED, element: <TasksPage /> },

    { path: routes.APPLICATIONS_APPLICATION(undefined), element: <ApplicationPage /> },
    {
      path: routes.APPLICATIONS_APPLICATION_FORM(undefined, undefined, undefined),
      element: <ApplicationFormPage />,
    },
    { path: routes.APPLICATION_FEEDBACK_FORM(), element: <ApplicationsFeedbackFormPage /> },
    { path: routes.APPLICATION_FEEDBACK(), element: <ApplicationsFeedbackPage /> },

    // ------------------------------ РЕЕСТРЫ ------------------------------

    { path: routes.REGISTRIES_APPLICATION, element: <RegistersApplicationPage /> },
    { path: routes.REGISTER_APPLICATIONS(undefined), element: <RegisterApplicationsPage /> },

    { path: routes.REGISTRIES_TRAVEL_PACKAGE, element: <RegistersTravelPackagePage /> },
    { path: routes.REGISTER_TRAVEL_PACKAGES(undefined), element: <RegisterTravelPackagesPage /> },

    // ------------------------------ АНКЕТА ОБРАТНОЙ СВЯЗИ ПО ОРГАНИЗАЦИИ СКЛ И РОМ ------------------------------

    { path: routes.FEEDBACK, element: <FeedbackPage /> },

    // ------------------------------ АДМИНИСТРИРОВАНИЕ ------------------------------

    {
      path: routes.ADMINISTRATION,
      element: <Navigate to={osr ? routes.REPORTING(undefined) : routes.CALCULATION_SETTINGS} />,
    },
    { path: routes.CALCULATION_SETTINGS, element: <CalculationSettingsPage /> },
    { path: routes.FREQUENCY_SETTINGS, element: <FrequencySettingsPage /> },
    {
      path: routes.FEEDBACK_NOTIFICATIONS_SETTINGS,
      element: <FeedbackNotificationsSettingsPage />,
    },
    { path: routes.REPORTING(undefined), element: <ReportingPage /> },

    // ------------------------------ ДОКУМЕНТАЦИЯ ------------------------------

    { path: routes.DOCUMENTATIONS, element: <DocumentationsPage /> },
  ];
};
