import { NamePartsType } from "../api";

/**
 *
 * ------------------------------------------------------------------------------------------
 * **ПОЛУЧЕНИЕ ИНИЦИАЛОВ**
 *
 * *Функция формирует инициалы пользователя по переданным частям имени*
 *
 * *Приоритет для второй буквы инициалов отдаётся отчеству (если оно есть), если отчества нет — используется фамилия, а при её отсутствии берётся вторая буква имени*
 *
 * -
 *
 * @param nameParts - объект, содержащий части имени
 * @param nameParts.firstName - имя (обязательное)
 * @param nameParts.middleName - отчество (не обязательное)
 * @param nameParts.lastName - фамилия (не обязательное)
 * @returns строка, состоящая из двух символов, представляющих инициалы
 *
 */

export const getUserInitials = (nameParts: NamePartsType) => {
  const { firstName, middleName, lastName } = nameParts;

  const firstLetter = firstName.trim()[0].toUpperCase();
  const secondLetter =
    middleName?.trim()[0].toUpperCase() ??
    lastName?.trim()[0].toUpperCase() ??
    (firstName.trim().length > 1 ? firstName.trim()[1].toLowerCase() : "");

  return `${firstLetter}${secondLetter}`;
};

/**
 *
 * ------------------------------------------------------------------------------------------
 * **ПОЛУЧЕНИЕ ПОЛНОГО ИМЕНИ (ФИО)**
 *
 * *Функция формирует полное имя (ФИО) на основе переданных частей имени: фамилии, имени и отчества*
 *
 * *Также позволяет исключить фамилию или отчество из результата*
 *
 *
 * -
 *
 * @param props - объект с параметрами
 * @param props.nameParts - части имени
 * @param props.nameParts.firstName - имя (обязательное)
 * @param props.nameParts.middleName - отчество (не обязательное)
 * @param props.nameParts.lastName - фамилия (не обязательное)
 * @param props.withoutLastName - указание на то, что фамилию следует исключить (не обязательный)
 * @param props.withoutMiddleName - указание на то, что отчество следует исключить (не обязательный)
 * @returns строка, содержащая полное имя с учётом переданных параметров и опций
 *
 */

export const getFullName = (props: PropsType) => {
  const { nameParts, withoutLastName = false, withoutMiddleName = false } = props;

  if (!nameParts) return "";

  const { firstName, middleName, lastName } = nameParts;

  const formattedFirstName = firstName ? ` ${firstName.trim()}` : "";
  const formattedMiddleName = withoutMiddleName ? "" : middleName ? ` ${middleName.trim()}` : "";
  const formattedLastName = withoutLastName ? "" : lastName ? lastName.trim() : "";

  return `${formattedLastName}${formattedFirstName}${formattedMiddleName}`.trimStart();
};

type PropsType = {
  nameParts: NamePartsType | null;
  withoutLastName?: boolean;
  withoutMiddleName?: boolean;
};

/**
 *
 * ------------------------------------------------------------------------------------------
 * **ПОЛУЧЕНИЕ ИМЕНИ ПОЛЬЗОВАТЕЛЯ (ФамилияИмОт)**
 *
 * *Функция формирует строку, состоящую из фамилии, имени и отчества в транслитерированной форме, ориентируясь на правила, установленные Приказом МИД России от 12.02.2020 № 2113*
 *
 * -
 *
 * @param nameParts - части имени
 * @param nameParts.firstName - имя (string)
 * @param nameParts.middleName - отчество (string) (не обязательный)
 * @param nameParts.lastName - фамилия (string) (не обязательный)
 * @returns строка вида "ФамилияИмОт" в транслитерированном формате
 *
 * @description
 * *Особенности*:
 * - При отсутствии фамилии или отчества эти части пропускаются, а формат адаптируется соответственно
 *
 */

export const getUsername = (nameParts: NamePartsType) => {
  const { firstName, middleName, lastName } = nameParts;

  type LettersType = (keyof typeof transliterationRules)[];

  const mapper = (str: string, length: number) =>
    (Array.from(str.trim().toLowerCase()) as LettersType)
      .slice(0, length)
      .map((letter) => transliterationRules[letter])
      .join("");

  const transliteratedFirstName = mapper(firstName, 2);
  const transliteratedMiddleName = middleName ? mapper(middleName, 2) : "";
  const transliteratedLastName = lastName ? mapper(lastName, lastName.trim().length) : "";

  return `${transliteratedLastName.charAt(0).toUpperCase() + transliteratedLastName.slice(1)}${
    transliteratedFirstName.charAt(0).toUpperCase() + transliteratedFirstName.slice(1)
  }${transliteratedMiddleName.charAt(0).toUpperCase() + transliteratedMiddleName.slice(1)}`;
};

const transliterationRules = {
  а: "a",
  б: "b",
  в: "v",
  г: "g",
  д: "d",
  е: "e",
  ё: "e",
  ж: "zh",
  з: "z",
  и: "i",
  й: "i",
  к: "k",
  л: "l",
  м: "m",
  н: "n",
  о: "o",
  п: "p",
  р: "r",
  с: "s",
  т: "t",
  у: "u",
  ф: "f",
  х: "kh",
  ц: "ts",
  ч: "ch",
  ш: "sh",
  щ: "shch",
  ъ: "ie",
  ы: "y",
  ь: "",
  э: "e",
  ю: "iu",
  я: "ia",
};
