import * as dateFns from "date-fns";
import { parseISO } from "date-fns";
import { BackendDateType, BigBackendDateType, FrontendDateType } from "../api";
import { DateSwitcherTypeType } from "../atoms";
import { arrMonths, arrYears } from "../constants/dates";
import { getInclinedWord } from "./getInclinedWord";

const { differenceInYears, differenceInMonths, differenceInDays } = dateFns;
const { format, parse, startOfDay, getTime } = dateFns;
const { addDays, addMonths, addQuarters, addYears } = dateFns;
const { subDays, subMonths, subQuarters, subYears } = dateFns;
const { startOfISOWeek, startOfMonth, startOfQuarter, startOfYear } = dateFns;
const { endOfISOWeek, endOfMonth, endOfQuarter, endOfYear } = dateFns;

export type DateType = Date | FrontendDateType | BackendDateType | BigBackendDateType;

/**
 *
 * ------------------------------------------------------------------------------------------
 * ТЕКУЩИЙ ДЕНЬ
 *
 */

export const now = new Date();

/**
 *
 * ------------------------------------------------------------------------------------------
 * НАЧАЛО ТЕКУЩЕГО ДНЯ
 *
 */

export const today = startOfDay(now);

/**
 *
 * ------------------------------------------------------------------------------------------
 * **ОПРЕДЕЛЕНИЕ ДЕЙСТВИТЕЛЬНОСТИ ДАТЫ**
 *
 * *Функция проверяет, является ли переданный аргумент корректным объектом Date*
 *
 * -
 *
 * @param date - предполагаемый объект даты
 * @returns булево значение: `true`, если аргумент является корректной датой, и `false` в противном случае
 *
 */

export const isDateObject = (date: unknown) => date instanceof Date && !isNaN(getTime(date));

/**
 *
 * ------------------------------------------------------------------------------------------
 * **ФОРМАТИРОВАНИЕ ДАТЫ**
 *
 * *Форматирует дату в зависимости от типа форматирования, указанного в параметрах*
 *
 * -
 *
 * @param props - параметры
 * @param props.date - дата, которую необходимо отформатировать (строка или объект типа Date)
 * @param props.type - тип форматирования
 * @returns отформатированное значение в зависимости от указанного типа
 *
 * @description
 * *props.type* может быть одним из следующих:
 * - "object" - для получения объекта Date
 * - "forFrontend" - для получения даты в формате дд.ММ.гггг
 * - "forBackend" - для получения даты в формате гггг-ММ-дд
 * - "bigDate" - для получения даты с временем гггг-ММ-ддT00:00:00.000
 *
 */

export const formatDate = <T extends TypeType>(props: FormatDatePropsType<T>) => {
  const { date, type } = props;

  const dateIsString = typeof date === "string";
  const dateIncludesT = dateIsString && date.includes("T");
  const dateIncludesDash = dateIsString && date.includes("-");

  const fMask = "dd.MM.yyyy";
  const bMask = "yyyy-MM-dd";

  const dateObject = dateIsString
    ? dateIncludesT
      ? parseISO(date)
      : parse(date, dateIncludesDash ? bMask : fMask, new Date())
    : date;

  const dateForFrontend = format(dateObject, fMask);
  const dateForBackend = format(dateObject, bMask);

  return (
    type === "object"
      ? dateObject
      : type === "forFrontend"
      ? dateForFrontend
      : type === "bigDate"
      ? `${dateForBackend}T00:00:00.000`
      : dateForBackend
  ) as FormatDateResultType<T>;
};

type FormatDatePropsType<T> = {
  date: DateType;
  type: T;
};

export type FormatDateResultType<T> = T extends "object"
  ? Date
  : T extends "forFrontend"
  ? FrontendDateType
  : T extends "bigDate"
  ? BigBackendDateType
  : BackendDateType;

export type TypeType = "object" | "forFrontend" | "forBackend" | "bigDate";

/**
 *
 * ------------------------------------------------------------------------------------------
 * **ВЫЧИСЛЕНИЕ КОЛИЧЕСТВА ДНЕЙ/НОЧЕЙ**
 *
 * *Функция вычисляет количество дней или ночей между двумя датами*
 *
 * -
 *
 * @param since - дата начала периода
 * @param until - дата конца периода
 * @param type - тип результата - дни или ночи
 * @returns количество дней или ночей в зависимости от указанного типа
 *
 * @description
 * *type* может быть одним из следующих:
 * - "days" (по умолчанию) — для получения количества дней между двумя датами, включая начальную и конечную
 * - "nights" — для получения количества ночей между двумя датами, исключая начальную дату
 *
 */

export const getDaysOrNightsInPeriod = (props: GetDaysOrNightsInPeriodPropsType) => {
  const { since, until, type = "days" } = props;

  const sinceDate = formatDate({ date: since, type: "object" });
  const untilDate = formatDate({ date: until, type: "object" });

  const nights = differenceInDays(untilDate, sinceDate);

  return type === "days" ? nights + 1 : nights;
};

type GetDaysOrNightsInPeriodPropsType = {
  since: DateType;
  until: DateType;
  type?: "days" | "nights";
};

/**
 *
 * ------------------------------------------------------------------------------------------
 * **ВЫЧИСЛЕНИЕ РАЗНИЦЫ МЕЖДУ СЕЙЧАС И ОПРЕДЕЛЁННОЙ ДАТОЙ**
 *
 * *Функция вычисляет разницу между текущей датой и переданной датой в годах, месяцах или днях*
 *
 * -
 *
 * @param date - дата, разница с которой вычисляется
 * @param period - единица измерения разницы
 * @returns число, представляющее разницу между текущей датой и переданной в указанной единице измерения
 *
 * @description
 * *period* может быть одним из следующих:
 * - "year" — возвращает разницу в годах
 * - "month" — возвращает разницу в месяцах
 * - "day" — возвращает разницу в днях
 *
 * *Особенности*:
 * - Возвращаемое значение всегда будет положительным, так как функция использует модуль разницы
 *
 * *Примеры использования*:
 * - Рассчитать возраст в годах
 * - Рассчитать стаж в месяцах или днях
 *
 */

export const differenceWithToday = (date: DateType, period: PeriodType = "year") => {
  const dateObject = formatDate({ date, type: "object" });

  const differenceMapper: Record<PeriodType, number> = {
    day: differenceInDays(today, dateObject),
    month: differenceInMonths(today, dateObject),
    year: differenceInYears(today, dateObject),
  };

  return differenceMapper[period];
};

type PeriodType = "year" | "month" | "day";

/**
 *
 * ------------------------------------------------------------------------------------------
 * **ВЫЧИСЛЕНИЕ РАЗНИЦЫ МЕЖДУ СЕЙЧАС И ОПРЕДЕЛЁННОЙ ДАТОЙ**
 *
 * *Функция вычисляет разницу между текущей датой и переданной датой в месяцах и/или годах*
 *
 * *Результат возвращается в виде удобной для отображения в интерфейсе строки*
 *
 * -
 *
 * @param date - дата, относительно которой вычисляется разница
 * @returns строка, описывающая разницу в годах и/или месяцах
 *
 *
 *
 * @description
 *
 * *Особенности*:
 * - результат представлен в месяцах, если менее года и в годах и месяцах - если более
 *
 * *Примеры использования*:
 * - Отобразить возраст в годах
 * - Отобразить стаж в месяцах
 *
 */

export const differenceWithTodayText = (date: DateType) => {
  const yearsQuantity = differenceWithToday(date);
  const monthsQuantity = differenceWithToday(date, "month") % 12;
  const daysQuantity = differenceWithToday(date, "day");

  const yearsStr = `${yearsQuantity} ${getInclinedWord(yearsQuantity, arrYears)}`;
  const monthsStr = `${monthsQuantity} ${getInclinedWord(monthsQuantity, arrMonths)}`;

  return daysQuantity < 0
    ? "—"
    : yearsQuantity === 0
    ? monthsStr
    : monthsQuantity === 0
    ? yearsStr
    : yearsQuantity > 0 && monthsQuantity > 0
    ? `${yearsStr} ${monthsStr}`
    : "—";
};

/**
 *
 * ------------------------------------------------------------------------------------------
 * **ПОЛУЧЕНИЕ СТРОКИ ПЕРИОДА**
 *
 * *Функция формирует строковое представление периода на основе начальной и конечной дат*
 *
 * -
 *
 * @param props - объект с параметрами функции
 * @param props.since - дата начала периода или null
 * @param props.until - дата окончания периода или null
 * @param props.withText - указание на то, что период требуется в формате "с/по", а не через дефис - если не передан, то формат через дефис
 * @returns строка, представляющая период
 *
 * @description
 * *Особенности*:
 * - должна быть передана хотя бы одна дата
 *
 * *Примеры итоговой строки*:
 * - через дефис: "дд.ММ.гггг — дд.ММ.гггг"
 * - с текстовыми префиксами: "с дд.ММ.гггг по дд.ММ.гггг"
 * - только начальная дата: "с дд.ММ.гггг"
 * - только конечная дата: "до дд.ММ.гггг"
 *
 */

export const getPeriodString = (props: GetPeriodStringPropsType) => {
  const { since, until, withText } = props as GetPeriodStringType;

  const sinceDate = since && formatDate({ date: since, type: "forFrontend" });
  const untilDate = until && formatDate({ date: until, type: "forFrontend" });

  const sinceString = since ? `${withText ? "с " : ""}${sinceDate}` : "";
  const untilString = until
    ? `${withText ? (since ? " по " : "по ") : since ? " — " : "до "}${untilDate}`
    : "";

  return `${sinceString}${untilString}`;
};

type GetPeriodStringPropsType = {
  withText?: boolean;
} & ({ since: DateType | null } | { until: DateType | null });

type GetPeriodStringType = {
  since?: DateType | null | undefined;
  until?: DateType | null | undefined;
  withText?: boolean;
};

/**
 *
 * ------------------------------------------------------------------------------------------
 * **МЕТОДЫ ПОЛУЧЕНИЯ НАЧАЛА И КОНЦА ПЕРИОДА**
 *
 * -
 *
 * @param start.year - начало года
 * @param start.quarter - начало квартала
 * @param start"day - начало месяца
 * @param start.period - начало периода
 * @param end.year - конец года
 * @param end.quarter - конец квартала
 * @param end.month - конец месяца
 * @param end.period - конец периода
 *
 */

export const startAndEndMethods = {
  start: {
    year: startOfYear,
    quarter: startOfQuarter,
    month: startOfMonth,
    period: startOfISOWeek,
  },
  end: {
    year: endOfYear,
    quarter: endOfQuarter,
    month: endOfMonth,
    period: endOfISOWeek,
  },
} as Record<"start" | "end", Record<DateSwitcherTypeType, (date: Date | number) => Date>>;

/**
 *
 * ------------------------------------------------------------------------------------------
 * **МЕТОДЫ ДОБАВЛЕНИЯ/УДАЛЕНИЯ ЕДИНИЦЫ ПЕРИОДА**
 *
 * -
 *
 * type DateSwitcherTypeType = "day" | "month" | "quarter" | "year"
 *
 * -
 *
 * @param plus.year - плюс 1 год
 * @param plus.quarter - плюс 1 квартал
 * @param plus.month - плюс 1 месяц
 * @param plus.day - плюс 1 день
 * @param minus.year - минус 1 год
 * @param minus.quarter - минус 1 квартал
 * @param minus.month - минус 1 месяц
 * @param minus.day - минус 1 день
 *
 */

export const onePeriodMethods = {
  plus: {
    year: (date: Date) => addYears(date, 1),
    quarter: (date: Date) => addQuarters(date, 1),
    month: (date: Date) => addMonths(date, 1),
    day: (date: Date) => addDays(date, 1),
  },
  minus: {
    year: (date: Date) => subYears(date, 1),
    quarter: (date: Date) => subQuarters(date, 1),
    month: (date: Date) => subMonths(date, 1),
    day: (date: Date) => subDays(date, 1),
  },
} as Record<"plus" | "minus", Record<DateSwitcherTypeType, (date: Date) => Date>>;
