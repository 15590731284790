import { Expand, Reduce } from "@digitalatom/ui";
import { useAtom } from "jotai";
import React, { Dispatch, FC, SetStateAction } from "react";
import { menuIsExpandState } from "../../../atoms";
import { Hint, useHint } from "../components";
import { Container } from "./ExpandButton.styles";

export const ExpandButton: FC<PropsType> = (props) => {
  const { setOpenSubMenu } = props;

  const [menuIsExpand, setMenuIsExpand] = useAtom(menuIsExpandState);

  const text = (expand: boolean) => (expand ? "Развернуть меню" : "Свернуть меню");

  const popoverHoverMethods = useHint("expandButton", text(!menuIsExpand));
  const { argsForHint, setPopoverHoverText } = popoverHoverMethods;

  const expandMenu = () => {
    setMenuIsExpand((prevState) => !prevState);
    setOpenSubMenu(undefined);
    setPopoverHoverText(text(menuIsExpand));
  };

  return (
    <Container onClick={expandMenu} {...argsForHint}>
      {menuIsExpand ? <Reduce /> : <Expand />}

      <Hint type="expandButton" popoverHoverMethods={popoverHoverMethods} />
    </Container>
  );
};

type PropsType = {
  setOpenSubMenu: Dispatch<SetStateAction<string | undefined>>;
};
