import styled from "@emotion/styled";
import { borders, colors, flex, margins, size, text } from "../../../styles";

export const Wrapper = styled.div<{ hasError?: boolean; forDisplay?: boolean }>`
  ${size({ w: "100%" })}
  ${flex({ gap: 8, horizontal: "center", vertical: "center" })}
  ${margins({ p: "12 16" })}
  ${({ hasError, forDisplay }) =>
    borders({
      radius: 16,
      color: hasError ? colors.redDefault : forDisplay ? colors.grayscaleInput : undefined,
    })}
  background: ${({ hasError, forDisplay }) =>
    hasError
      ? colors.redBackground
      : forDisplay
      ? colors.grayscaleOffWhite
      : colors.grayscaleInput};
`;

export const Label = styled.span`
  ${text({ weight: 400, size: 15, height: 19.5, color: colors.grayscaleInputLabel })}
  flex-grow: 1;
`;

export const Score = styled.button<{ selected: boolean; forDisplay?: boolean }>`
  ${size({ w: 36, h: 36 })}
  ${flex({ horizontal: "center", vertical: "center" })}
  flex-shrink: 0;
  ${({ selected }) =>
    text({
      weight: 600,
      size: 13,
      height: 16.9,
      color: selected ? colors.white : colors.grayscaleBody,
    })}

  ${({ forDisplay }) =>
    borders({ totalRadius: true, color: forDisplay ? colors.grayscaleInput : colors.white })}
  
  background:  ${({ selected }) => (selected ? colors.primaryDefault : colors.white)};
  cursor: ${({ forDisplay }) => (forDisplay ? "default" : "pointer")};

  &:hover {
    ${({ selected, forDisplay }) =>
      selected || forDisplay
        ? ""
        : borders({ totalRadius: true, width: 2, color: colors.primaryDefault })};
    ${({ selected, forDisplay }) =>
      selected || forDisplay
        ? ""
        : text({ weight: 600, size: 13, height: 16.9, color: colors.primaryDefault })};
  }
`;
