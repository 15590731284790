import { CheckForSwitch } from "@digitalatom/ui";
import React, { FC, MouseEvent } from "react";
import { Cross } from "../../uiKit";
import { Container, Label, Switch } from "./SwitchLabelInput.styles";

export const SwitchLabel: FC<PropsType> = (props) => {
  const { label, onChange, value, disabled = false } = props;

  return (
    <Container
      onClick={(evt) => !disabled && onChange(!value, evt)}
      disabled={disabled}
      withOutLable={!label}
    >
      <Label disabled={disabled}>{label}</Label>

      <Switch checked={value}>
        <div>{value ? <CheckForSwitch /> : <Cross size={8} />}</div>
      </Switch>
    </Container>
  );
};

type PropsType = {
  label?: string;
  onChange: (value: boolean, evt: MouseEvent) => void;
  value: boolean;
  disabled?: boolean;
};
