import React, { FC } from "react";
import { useController, useFormContext } from "react-hook-form";
import { PropsSliderNumberType, SliderNumber } from "./SliderNumber";

export const SliderNumberInput: FC<PropsType> = (props) => {
  const { name } = props;

  const controller = useController({ name });
  const { field } = controller;
  const { onBlur: handleBlur } = field;
  const context = useFormContext();
  const setValue = (data: number) => context.setValue(name, data);

  return <SliderNumber {...props} onBlur={handleBlur} onChange={setValue} editMode />;
};

type PropsType = PropsSliderNumberType & {
  name: string;
};
