import { ArrowRound, CheckMark, CrossOnCircle, ExclamationMark, Export } from "@digitalatom/ui";
import { useAtom } from "jotai";
import React, { FC, useEffect, useRef } from "react";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { statusIconInputState } from "../../atoms";
import { preventDefault } from "../../utils/preventDefault";
import { TrashButton } from "../Buttons";
import * as icons from "../Icons";
import { Container, Input } from "./IconInput.styles";
import { useIconInput } from "./useIconInput";

const { prefixIcon } = icons;

export const IconInput: FC = () => {
  const name = "icon";

  const [status, setStatus] = useAtom(statusIconInputState);

  const controller = useController({ name });
  const { fieldState } = controller;
  const { error: errorObj } = fieldState;
  const error = errorObj?.message;
  const { isTouched: touched } = fieldState;

  const context = useFormContext();
  const { submitCount } = context.formState;
  const setValue = (data: File | undefined) => context.setValue(name, data);

  const value = useWatch({ name }) as File | string | undefined;

  const ref = useRef<HTMLInputElement>(null);

  const { onChange, handleRemove, handleDrop, element } = useIconInput(setValue);

  useEffect(() => {
    !!value && setStatus("wasFile");
    return () => setStatus("empty");
  }, []);

  useEffect(() => {
    if (status === "wasFile") context.trigger(name);
  }, [status]);

  useEffect(() => {
    error && (!!touched || submitCount) && setStatus("error");
  }, [error, submitCount]);

  const icon = !!value && typeof value !== "string" ? value : undefined;
  const iconName = icon?.name;
  const iconSize = icon ? `${Math.round(icon?.size / 1024)}Кб` : "";

  return (
    <>
      {status === "empty" ? (
        <Container
          status={status}
          onClick={() => ref.current?.click()}
          onDragOver={preventDefault}
          onDragLeave={preventDefault}
          onDrop={handleDrop}
        >
          {element({
            icon: <Export />,
            title: "Прикрепите иконку для отображения *",
            description: "Подходят файлы в формате .svg 24x24px до 20Кб",
          })}
        </Container>
      ) : (
        <Container status={status} as="div">
          {status === "error" ? (
            element({
              icon: <ExclamationMark />,
              buttonIcon: <ArrowRound />,
              onClick: () => handleRemove(ref),
              title: iconName,
              description: error ?? "",
            })
          ) : status === "progress" ? (
            element({
              buttonIcon: <CrossOnCircle />,
              onClick: () => handleRemove(ref),
              needProgress: true,
              title: iconName,
              description: iconSize,
            })
          ) : status === "success" ? (
            element({
              icon: <CheckMark />,
              needEmptiness: true,
              title: "Иконка загружена",
              description: "Обработка завершается...",
            })
          ) : status === "wasFile" ? (
            <>
              <img
                alt="picture"
                src={value instanceof File ? URL.createObjectURL(value) : `${prefixIcon}${value}`}
              />

              <TrashButton onClick={() => handleRemove(ref)} />
            </>
          ) : null}
        </Container>
      )}

      <Input ref={ref} name={name} type="file" accept=".svg" onChange={onChange} />
    </>
  );
};
